import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const DependsOnExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.DependsOn;
    
/** In this example, we use the @DependsOn annotation to specify 
 * that the secondBean() method depends on the firstBean(). 
 * This ensures that the firstBean is initialized before the 
 * secondBean during the application context startup. */

    @Configuration
    public class MyConfig {
    
        @Bean
        public FirstBean firstBean() {
            return new FirstBean();
        }
        
        @Bean
        @DependsOn("firstBean")
        public SecondBean secondBean() {
            return new SecondBean();
        }
    }
    
    public class FirstBean {
        // Bean logic here
    }
    
    public class SecondBean {
        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default DependsOnExample;
