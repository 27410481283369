import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const QualifierExample = () => {
  const javaCode = `
    import org.springframework.beans.factory.annotation.Qualifier;
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    
/** In this example, we use the @Qualifier annotation to specify 
 * the name of the bean to be injected when there are multiple 
 * beans of the same type (in this case, MyBean). The @Qualifier 
 * annotation allows you to provide a hint to Spring about which 
 * bean to use in case of ambiguity. */

    @Configuration
    public class MyConfig {
    
        @Bean
        @Qualifier("beanA")
        public MyBean beanA() {
            return new MyBean("Bean A");
        }
        
        @Bean
        @Qualifier("beanB")
        public MyBean beanB() {
            return new MyBean("Bean B");
        }
    }
    
    public class MyBean {
        private String name;
        
        public MyBean(String name) {
            this.name = name;
        }
        
        public String getName() {
            return name;
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default QualifierExample;
