import React from "react";
import Masonry from "react-masonry-component";
import { createGlobalStyle } from "styled-components";
import AOS from "aos";
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

const Gallery = function () {
  const handleBtnClick1 = () => {
    window.open(
      "https://www.interaction-design.org/members/ufuk-tosun/certificate/course/81b139cb-0012-4edb-9eaa-893ffc8e84d9",
      "_blank"
    );
  };

  const handleBtnClick2 = () => {
    window.open(
      "https://www.interaction-design.org/members/ufuk-tosun/certificate/course/2cf825d2-f2cf-406f-b28e-8f337c9c7d5e",
      "_blank"
    );
  };

  const handleBtnClick3 = () => {
    window.open(
      "https://www.interaction-design.org/members/ufuk-tosun/certificate/course/145ed5ab-7e0d-48ea-8503-6edade5eec4a",
      "_blank"
    );
  };
  const handleBtnClick4 = () => {
    window.open(
      "https://www.interaction-design.org/members/ufuk-tosun/certificate/course/442a58d0-f137-4700-9365-199f99717fae",
      "_blank"
    );
  };
  const handleBtnClick5 = () => {
    window.open(
      "https://www.interaction-design.org/members/ufuk-tosun/certificate/course/e2e65b00-3fad-4d47-8036-081854ff66cf",
      "_blank"
    );
  };
  const handleBtnClick6 = () => {
    window.open("https://www.linkedin.com/in/tosunufuk", "_blank");
  };

  return (
    <div className="container">
      <GlobalStyles />
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Certificates</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <Masonry className={"row my-gallery-class"} elementType={"div"}>
        <div
          className="col-lg-4 image-element-class de_modal de_modal"
          onClick={handleBtnClick1}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Mobile UX Design</h3>
              <h5 className="d-tag">IDxF</h5>
            </div>
            <img
              src="./img/gallery/cc-mobile-ux-design-course-the-beginners-guide.jpeg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick2}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Design Thinking</h3>
              <h5 className="d-tag">IDxF</h5>
            </div>
            <img
              src="./img/gallery/cc-design-thinking-the-ultimate-guide.jpeg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick3}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>UX Beginners Guide</h3>
              <h5 className="d-tag">IDxF</h5>
            </div>
            <img
              src="./img/gallery/cc-user-experience-the-beginner-s-guide.jpeg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick4}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Human-Computer Interaction: The Foundations of UX Design</h3>
              <h5 className="d-tag">IDxF</h5>
            </div>
            <img
              src="./img/gallery/cc-hci-foundations-of-ux-design.jpg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick5}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>Visual Design: The Ultimate Guide</h3>
              <h5 className="d-tag">IDxF</h5>
            </div>
            <img
              src="./img/gallery/cc-visual-design-the-ultimate-guide.jpg"
              alt="gallery"
            />
          </div>
        </div>
        <div
          className="col-lg-4 image-element-class de_modal"
          onClick={handleBtnClick6}
          data-aos="fade-up"
          data-aos-once="true"
        >
          <div className="card-image-1">
            <div className="d-text">
              <h3>for more</h3>
              <h5 className="d-tag">to LinkedIn</h5>
            </div>
            <img src="./img/gallery/linkedin.png" alt="gallery" />
          </div>
        </div>
      </Masonry>
    </div>
  );
};

export default Gallery;
