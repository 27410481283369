import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIFlexibility = () => {
  const javaCode = `
  // Collection
  List<String> list = Arrays.asList("A", "B", "C");
  Stream<String> streamFromList = list.stream();
  
  // Array
  String[] array = {"D", "E", "F"};
  Stream<String> streamFromArray = Arrays.stream(array);
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIFlexibility;
