import React from "react";
import ReactiveStreams from "./codes/reactivestreams/ReactiveStreams";

const notesReactiveStreams = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesReactiveStreams.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 9 Reactive Streams API</h4>
      <div className="post-text">
        <ul>
          <li>
            <strong>Introduction:</strong>
            <p>
              Java 9 introduced the Reactive Streams API as part of
              `java.util.concurrent.Flow`, providing a standardized way for
              asynchronous stream processing with non-blocking backpressure.
            </p>
          </li>
          <li>
            <strong>Benefits of Reactive Streams API:</strong>
            <ul>
              <li>
                <strong>Asynchronous Stream Processing:</strong> Reactive
                Streams enable the asynchronous processing of data streams,
                suitable for scenarios with varying data processing speeds.
              </li>
              <li>
                <strong>Non-blocking Backpressure:</strong> The API introduces a
                mechanism for non-blocking backpressure, allowing subscribers to
                control the rate at which they consume data, preventing
                overwhelm.
              </li>
              <li>
                <strong>Standardization:</strong> The API provides a
                standardization for reactive programming in Java, facilitating
                interoperability between different libraries and frameworks.
              </li>
            </ul>
          </li>
          <li>
            <strong>Upsides:</strong>
            <ul>
              <li>
                <strong>Resilient and Efficient:</strong> Reactive Streams help
                build more resilient and efficient systems by managing the flow
                of data in an asynchronous and non-blocking manner.
              </li>
              <li>
                <strong>Interoperability:</strong> Standardizing reactive
                programming allows developers to use various libraries and
                frameworks that support the Reactive Streams API seamlessly.
              </li>
            </ul>
          </li>
          <li>
            <strong>Downsides and Challenges:</strong>
            <ul>
              <li>
                <strong>Learning Curve:</strong> Developers might face a
                learning curve when transitioning to reactive programming
                paradigms and understanding backpressure concepts.
              </li>
              <li>
                <strong>Complexity in Debugging:</strong> Asynchronous and
                non-blocking code can be more challenging to debug compared to
                traditional synchronous code.
              </li>
            </ul>
          </li>
          <li>
            <strong>Examples:</strong>
            <ReactiveStreams />
            <strong>In this example:</strong>
            <ul>
              <li>
                A simple example demonstrates the creation of a
                `SubmissionPublisher` (a Publisher implementation) and a simple
                `Subscriber`. Data is asynchronously published to the
                subscriber, showcasing the basics of Reactive Streams in Java 9.
              </li>
            </ul>
          </li>
          <li>
            <strong>Conclusion:</strong>
            <p>
              Java 9's introduction of the Reactive Streams API provides a
              standardized approach to asynchronous and non-blocking stream
              processing with backpressure. Developers can build more resilient
              and efficient systems while benefiting from the interoperability
              of libraries and frameworks supporting the Reactive Streams API.
              However, understanding and mastering the reactive programming
              paradigm may pose initial challenges.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default notesReactiveStreams;
