import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIReadability = () => {
  const javaCode = `
  List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5);

  // Traditional Approach
  int sum = 0;
  for (int num : numbers) {
      sum += num;
  }

  // Stream Approach
  int sumStream = numbers.stream().reduce(0, Integer::sum);
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIReadability;
