import React from "react";
import Java8LambdaExpression from "./codes/java8/Java8LambdaExpression";
import Java8FunctionalInterface from "./codes/java8/Java8FunctionalInterface";
import Java8StreamsAPI from "./codes/java8/Java8StreamsAPI";
import Java8DefaultMethods from "./codes/java8/Java8DefaultMethods";
import Java8MethodReferences from "./codes/java8/Java8MethodReferences";
import Java8FunctionalInterfacePredicate from "./codes/java8/Java8FunctionalInterfacePredicate";
import Java8FunctionalInterfaceFunction from "./codes/java8/Java8FunctionalInterfaceFunction";
import Java8FunctionalInterfaceConsumer from "./codes/java8/Java8FunctionalInterfaceConsumer";
import Java8FunctionalInterfaceSupplier from "./codes/java8/Java8FunctionalInterfaceSupplier";
import Java8FunctionalInterfaceUnaryOperator from "./codes/java8/Java8FunctionalInterfaceUnaryOperator";
import Java8FunctionalInterfaceBinaryOperator from "./codes/java8/Java8FunctionalInterfaceBinaryOperator";
import Java8Optional from "./codes/java8/Java8Optional";
import Java8DateAndTimeAPI from "./codes/java8/Java8DateAndTimeAPI";
import Java8Nashorn from "./codes/java8/Java8Nashorn";
import Java8Collectors from "./codes/java8/Java8Collectors";

const notesJava8 = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notes8.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <ul>
          <li>
            <b>Lambda Expressions:</b> Lambda expressions allow you to write
            more concise and readable code by providing a way to express
            instances of single-method interfaces (functional interfaces) using
            a compact syntax.
          </li>
          <Java8LambdaExpression />
          <li>
            <b>Functional Interfaces:</b> Java 8 introduced the
            @FunctionalInterface annotation to indicate that an interface is
            intended to be a functional interface, which can have only one
            abstract method.
          </li>
          <Java8FunctionalInterface />
          <li>
            <b>Streams API:</b> Streams provide a powerful and flexible way to
            process collections of data. You can perform operations such as
            filtering, mapping, and reducing on collections in a declarative
            way.
          </li>
          <Java8StreamsAPI />
          <li>
            <b>Default Methods:</b> Default methods allow you to add new methods
            to existing interfaces without breaking the classes that implement
            those interfaces. This is useful for backward compatibility.
          </li>
          <Java8DefaultMethods />
          <li>
            <b>Method References:</b> Method references provide a shorthand
            notation for lambda expressions to call a method.
          </li>
          <Java8MethodReferences />
          <li>
            <b>Functional Interfaces in Java Standard Library:</b> Java 8
            introduced several new functional interfaces in the
            java.util.function package, such as Predicate, Function, Consumer,
            and Supplier, which are commonly used in functional programming.
            <ul>
              <li>
                <b>Predicate&lt;T&gt;:</b> Represents a predicate
                (boolean-valued function) of one argument. It has a single
                method: `boolean test(T t)`.
              </li>
              <Java8FunctionalInterfacePredicate />
              <li>
                <b>Function&lt;T, R&gt;:</b> Represents a function that takes
                one argument and produces a result. It has a single method: `R
                apply(T t)`.
              </li>
              <Java8FunctionalInterfaceFunction />
              <li>
                <b>Consumer&lt;T&gt;:</b> Represents an operation that takes a
                single input argument and returns no result. It has a single
                method: `void accept(T t)`.
              </li>
              <Java8FunctionalInterfaceConsumer />
              <li>
                <b>Supplier&lt;T&gt;:</b> Represents a supplier of results,
                i.e., a no-argument function. It has a single method: `T get()`.
              </li>
              <Java8FunctionalInterfaceSupplier />
              <li>
                <b>UnaryOperator&lt;T&gt;:</b> Represents an operation on a
                single operand that produces a result of the same type as its
                operand. It has a single method: `T apply(T t)`.
              </li>
              <Java8FunctionalInterfaceUnaryOperator />
              <li>
                <b>BinaryOperator&lt;T&gt;:</b> Represents an operation upon two
                operands of the same type, producing a result of the same type
                as the operands. It has a single method: `T apply(T t1, T t2)`.
              </li>
              <Java8FunctionalInterfaceBinaryOperator />
            </ul>
          </li>
          <li>
            <b>Optional:</b> The Optional class was introduced to deal with null
            values more effectively and avoid null pointer exceptions.
          </li>
          <Java8Optional />
          <li>
            <b>Date and Time API (java.time):</b> Java 8 introduced a new Date
            and Time API, which is more comprehensive and flexible than the
            previous java.util.Date and java.util.Calendar classes.
          </li>
          <Java8DateAndTimeAPI />
          <li>
            <b>Nashorn JavaScript Engine:</b> Java 8 includes a new JavaScript
            engine called Nashorn, which allows you to execute JavaScript code
            from within Java applications.
          </li>
          <Java8Nashorn />
          <li>
            <b>New Collectors:</b> The Collectors class introduced several new
            static factory methods to facilitate the creation of collectors for
            use with streams.
          </li>
          <Java8Collectors />
          <li>
            <b>PermGen Removal:</b> Java 8 removed the Permanent Generation
            (PermGen) space and introduced the Metaspace to handle class
            metadata, which helps to prevent class-loading memory leaks.
          </li>
          <li>
            <b>Parallel Streams:</b> You can easily parallelize operations on
            streams to take advantage of multi-core processors.
          </li>
          <li>
            <b>New APIs and Methods:</b> Java 8 introduced new APIs and methods
            in various classes, such as the Arrays class, Map interface, and
            others, to make common tasks more convenient.
          </li>
        </ul>
      </div>
    </div>
  );
};
export default notesJava8;
