import React from "react";
import Repeatable from "./codes/repeatable/Repeatable";
import RetentionPolicy from "./codes/repeatable/RetentionPolicy";

const notesRepeatable = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesReuse.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Repeatable Annotations</h4>
        <p>
          Java 8 Repeatable Annotations, which allows you to apply the same
          annotation multiple times to a single declaration. Prior to Java 8, an
          annotation could only be used once on a declaration, which sometimes
          led to the creation of container annotations to store multiple
          instances of the same annotation. Repeatable Annotations simplify the
          code and make it more readable.
        </p>
        <ul>
          <li>
            <h5>Multiple Occurrences of the Same Annotation:</h5>
            <b>Benefit:</b> Repeatable Annotations allow developers to apply the
            same annotation multiple times to a single declaration.
            <br />
            <b>Advantage:</b> Simplifies code and eliminates the need for
            container annotations to store multiple instances of the same
            annotation.
          </li>
          <li>
            <h5>Enhanced Readability:</h5>
            <b>Benefit:</b> The code becomes more concise and readable,
            especially in scenarios where multiple instances of the same
            annotation are needed.
            <br />
            <b>Advantage:</b> Improved code maintainability and reduced
            verbosity.
          </li>
          <li>
            <h5>Improved API Design:</h5>
            <b>Benefit:</b> APIs that use annotations can be designed in a more
            intuitive and flexible way, allowing users to provide multiple
            values for the same annotation.
            <br />
            <b>Advantage:</b> Provides a cleaner and more natural way to express
            certain configurations or metadata.
          </li>
        </ul>
        <p>Example:</p>
        <Repeatable />
        <h5>RetentionPolicy</h5>
        <p>
          The RetentionPolicy is an enumeration that is part of the
          java.lang.annotation package. It is used to specify how long an
          annotation type should be retained. Annotations can be retained at
          different levels, and RetentionPolicy provides three options:
        </p>
        <ul>
          <p>Module Declaration:</p>
          <li>
            Annotations with RetentionPolicy.SOURCE are only available in the
            source code. They are discarded by the compiler and do not appear in
            the compiled bytecode or the runtime.
          </li>
          <li>
            These annotations are typically used for documentation purposes or
            during development but do not impact the compiled or executed code.
          </li>
          <p>CLASS:</p>
          <li>
            Annotations with RetentionPolicy.CLASS are included in the compiled
            bytecode, but they are not accessible at runtime. They are available
            during compilation but are not retained in the generated class
            files.
          </li>
          <li>
            This retention policy is useful when you want the annotation
            information to be available to the compiler but not needed at
            runtime.
          </li>
          <p>RUNTIME:</p>
          <li>
            Annotations with RetentionPolicy.RUNTIME are stored in the compiled
            bytecode and can be accessed and utilized at runtime through
            reflection.
          </li>
          <li>
            This is the most flexible retention policy and is commonly used when
            annotations need to influence the behavior of the program at
            runtime.
          </li>
        </ul>
        <p>Example:</p>
        <RetentionPolicy />
      </div>
    </div>
  );
};
export default notesRepeatable;
