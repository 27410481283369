import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java10AULTE = () => {
  const javaCode = `
  import java.util.Locale;
  import java.util.stream.Stream;

  public class AdditionalUnicodeLanguageTagExtensions {

      public static void main(String[] args) {
          // Example of using extended language tags
          Locale extendedLocale1 = Locale.forLanguageTag("en-u-hc-h12");
          Locale extendedLocale2 = Locale.forLanguageTag("ja-JP-u-ca-japanese");

          System.out.println("Extended Language Tags:");
          System.out.println("Locale 1: " + extendedLocale1);
          System.out.println("Locale 2: " + extendedLocale2);

          // Example of processing extended language tags in a stream
          Stream.of("fr-FR-u-ca-gregory", "de-DE-u-nu-latn")
                  .map(Locale::forLanguageTag)
                  .forEach(System.out::println);
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java10AULTE;
