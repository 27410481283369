import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8FunctionalInterface = () => {
  const javaCode = `
// Functional Interfaces: Java 8 introduced the @FunctionalInterface annotation to indicate that an interface is intended to be a functional interface, which can have only one abstract method.
// Defining a functional interface
@FunctionalInterface
interface Calculator {
    int operate(int a, int b);
}

// Using a functional interface with a lambda expression
Calculator addition = (a, b) -> a + b;

  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8FunctionalInterface;
