import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8FunctionalInterfaceUnaryOperator = () => {
  const javaCode = `
  UnaryOperator<Integer> square = n -> n * n;
  int result = square.apply(5); // Result: 25
    `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8FunctionalInterfaceUnaryOperator;
