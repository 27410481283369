import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const IPAPI = () => {
  const javaCode = `
  // Code Example
  import java.io.IOException;
  import java.util.concurrent.TimeUnit;

  public class ProcessApiExample {
      public static void main(String[] args) throws IOException, InterruptedException {
          // Launch an external process
          ProcessBuilder processBuilder = new ProcessBuilder("echo", "Hello, Process API!");
          Process process = processBuilder.start();

          // Wait for the process to complete (with a timeout)
          boolean completed = process.waitFor(5, TimeUnit.SECONDS);

          // Print the process exit code
          System.out.println("Exit Code: " + process.exitValue());

          // Destroy the process if not completed
          if (!completed) {
              process.destroyForcibly();
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default IPAPI;
