import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8FunctionalInterfacePredicate = () => {
  const javaCode = `
Predicate<Integer> isEven = n -> n % 2 == 0;
boolean result = isEven.test(4); // Result: true
`;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8FunctionalInterfacePredicate;
