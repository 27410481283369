import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const WebpackUsingPathAliases = () => {
  const reactCode = `
  // File: src/components/myComponent.js
export const myComponent = 'My Component';

// File: src/utils/utilFunction.js
export const utilFunction = 'Utility Function';

// File: src/App.js (for example)
import { myComponent } from 'components/myComponent'; // Using the alias
import { utilFunction } from 'utils/utilFunction'; // Using the alias

console.log(myComponent); // Outputs: "My Component"
console.log(utilFunction); // Outputs: "Utility Function"
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="javascript"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default WebpackUsingPathAliases;
