import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const OrderAnnotationExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.core.annotation.Order;

/** In this example, we use the @Order annotation to specify 
 * the order in which beans are initialized when there are 
 * multiple beans of the same type. The lower the order value, 
 * the earlier the bean is initialized. */

    @Configuration
    public class MyConfiguration {

      @Bean
      @Order(2)
      public MyBean bean1() {
        return new MyBean("Bean 1");
      }

      @Bean
      @Order(1)
      public MyBean bean2() {
        return new MyBean("Bean 2");
      }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default OrderAnnotationExample;
