import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisQueryObjectsSimple = () => {
  const javaCode = `{
    "id": "1",
    "name": "Alice",
    "age": 29
  }
  {
    "id": "2",
    "name": "Bob",
    "age": 30
  }
  {
    "id": "3",
    "name": "Charlie",
    "age": 28
  }`;

  return (
    <div>
      <SyntaxHighlighter
        language="json"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisQueryObjectsSimple;
