import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const OptionalClassExample = () => {
  const javaCode = `
  import java.util.Optional;

  public class OptionalExample {
      public static void main(String[] args) {
          // Creating Optional with a Non-Null Value
          Optional<String> nonNullOptional = Optional.of("Hello, Optional!");

          // Creating Optional with a Potentially Null Value
          String nullableValue = null;
          Optional<String> nullableOptional = Optional.ofNullable(nullableValue);

          // Creating an Empty Optional
          Optional<String> emptyOptional = Optional.empty();

          // Checking if a Value is Present
          if (nonNullOptional.isPresent()) {
              System.out.println(nonNullOptional.get());  // Output: Hello, Optional!
          }

          // Conditional Execution with ifPresent
          nonNullOptional.ifPresent(value -> System.out.println("Value is present: " + value));

          // Default Value with orElse
          String result1 = nonNullOptional.orElse("Default Value");
          System.out.println(result1);  // Output: Hello, Optional!

          // Default Value with orElseGet
          String result2 = emptyOptional.orElseGet(() -> "Default Value from Supplier");
          System.out.println(result2);  // Output: Default Value from Supplier

          // Throwing an Exception with orElseThrow
          try {
              String result3 = emptyOptional.orElseThrow(() -> new RuntimeException("Value not present"));
          } catch (RuntimeException e) {
              System.out.println(e.getMessage());  // Output: Value not present
          }

          // Transforming and Filtering Values
          String transformedValue = nonNullOptional.map(String::toUpperCase).orElse("Default");
          System.out.println(transformedValue);  // Output: HELLO, OPTIONAL!

          // Filtering Values
          Optional<String> filteredOptional = nonNullOptional.filter(value -> value.length() > 10);
          System.out.println(filteredOptional.isPresent());  // Output: false
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default OptionalClassExample;
