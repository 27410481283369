import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java10ParallelG1 = () => {
  const javaCode = `
  import java.util.ArrayList;
  import java.util.List;

  public class ParallelG1Example {
      public static void main(String[] args) {
          System.out.println("Starting Parallel Full GC for G1 Example");

          // Generate garbage to trigger garbage collection
          generateGarbage();

          // Explicitly request garbage collection
          System.gc();

          System.out.println("Parallel Full GC for G1 Example Completed");
      }

      private static void generateGarbage() {
          List<String> garbageList = new ArrayList<>();

          for (int i = 0; i < 1_000_000; i++) {
              // Create and discard objects to generate garbage
              String garbage = new String("Garbage-" + i);
              garbageList.add(garbage);
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java10ParallelG1;
