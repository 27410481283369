import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ImportResourceExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.ImportResource;
    
/** In this example, we use the @ImportResource annotation to import 
 * an XML-based Spring configuration file (my-spring-config.xml) 
 * into a Spring configuration class (MyConfig). This allows you 
 * to use both Java-based and XML-based configuration together in 
 * your Spring application. */

    @Configuration
    @ImportResource("classpath:my-spring-config.xml")
    public class MyConfig {
        // Configuration class logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ImportResourceExample;
