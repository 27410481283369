import React from "react";
import Java9 from "./codes/java9/Java9";

const notesJava9 = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesJava9.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 9 key features</h4>
      <div className="post-text">
        <ul>
          <li>
            <strong>Module System (Project Jigsaw):</strong>
            <p>
              Java 9 introduced a module system to address modularity issues.
              Modules help in organizing code and managing dependencies more
              effectively.
            </p>
          </li>
          <li>
            <strong>JShell (Interactive Shell):</strong>
            <p>
              JShell is an interactive REPL (Read-Eval-Print Loop) that allows
              developers to interactively evaluate Java code snippets without
              the need for compilation.
            </p>
          </li>
          <li>
            <strong>HTTP/2 Client:</strong>
            <p>
              The HTTP/2 Client provides a modern and asynchronous API for
              interacting with HTTP/2 servers, offering improved performance and
              efficiency.
            </p>
          </li>
          <li>
            <strong>Improved Process API:</strong>
            <p>
              Java 9 enhanced the Process API for better control and information
              retrieval when interacting with external processes.
            </p>
          </li>
          <li>
            <strong>Private Methods in Interfaces:</strong>
            <p>
              Java 9 allows the declaration of private methods in interfaces to
              facilitate code reuse and organization.
            </p>
          </li>
          <li>
            <strong>Improved Stream API:</strong>
            <p>
              Dive into the enhancements made to the Stream API, including new
              methods like takeWhile, dropWhile, and other improvements for more
              expressive and powerful stream processing.
            </p>
          </li>
          <li>
            <strong>Try-With-Resources Enhancements:</strong>
            <p>
              Learn about the enhancements to the try-with-resources statement,
              which now supports effectively final variables for more concise
              resource management.
            </p>
          </li>
          <li>
            <strong>Diamond Operator for Anonymous Classes:</strong>
            <p>
              Explore the usage of the diamond operator (&lt&gt) with anonymous
              classes, making code more concise and readable.
            </p>
          </li>
          <li>
            <strong>Multi-Release JARs:</strong>
            <p>
              Understand the concept of multi-release JARs and how they allow
              developers to include different versions of classes for different
              Java releases within the same JAR file.
            </p>
          </li>
          <li>
            <strong>Reactive Streams API:</strong>
            <p>
              Learn about the Reactive Streams API and its role in supporting
              asynchronous stream processing with non-blocking backpressure.
            </p>
          </li>
          <li>
            <strong>Multi-Resolution Images:</strong>
            <p>
              Java 9 introduced the `MultiResolutionImage` interface and related
              classes to handle multi-resolution images more efficiently.
            </p>
          </li>
          <li>
            <strong>Java Platform Logging API and Service:</strong>
            <p>
              Java 9 introduced a new Logging API and a Service interface for
              pluggable logging frameworks.
            </p>
          </li>
          <li>
            <strong>Compact Strings:</strong>
            <p>
              Java 9 introduced the concept of compact strings to reduce the
              memory footprint of strings.
            </p>
          </li>
        </ul>
        <p>Examples:</p>
        <Java9 />
      </div>
    </div>
  );
};
export default notesJava9;
