import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const DateAndTimeAPIExample = () => {
  const javaCode = `
  import java.time.LocalDate;
  import java.time.LocalTime;
  import java.time.LocalDateTime;
  import java.time.format.DateTimeFormatter;

  public class DateTimeExample {
      public static void main(String[] args) {
          // LocalDate Example
          LocalDate currentDate = LocalDate.now();
          System.out.println("Current Date: " + currentDate);

          // LocalTime Example
          LocalTime currentTime = LocalTime.now();
          System.out.println("Current Time: " + currentTime);

          // LocalDateTime Example
          LocalDateTime currentDateTime = LocalDateTime.now();
          System.out.println("Current Date and Time: " + currentDateTime);

          // Formatting and Parsing
          DateTimeFormatter formatter = DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss");
          String formattedDateTime = currentDateTime.format(formatter);
          System.out.println("Formatted Date and Time: " + formattedDateTime);

          LocalDateTime parsedDateTime = LocalDateTime.parse("2023-11-13 15:30:00", formatter);
          System.out.println("Parsed Date and Time: " + parsedDateTime);
      }
  } 
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default DateAndTimeAPIExample;
