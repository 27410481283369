import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const UsingPathAliases = () => {
  const reactCode = `
  // File: src/components/myComponent.js
  module.exports = 'My Component';
  
  // File: src/utils/utilFunction.js
  module.exports = 'Utility Function';
  
  // File: index.js
  const myComponent = require('components/myComponent'); // Using the alias
  const utilFunction = require('utils/utilFunction'); // Using the alias
  
  console.log(myComponent); // Outputs: "My Component"
  console.log(utilFunction); // Outputs: "Utility Function"
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="javascript"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default UsingPathAliases;
