import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisActuator = () => {
  const javaCode = `
    management.endpoints.web.exposure.include=health,info,redis
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="properties"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisActuator;
