import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java10ACDSBashRun = () => {
  const javaCode = `
  java -XX:+UseAppCDS -Xshare:on -XX:SharedArchiveFile=app-cds.jsa -cp app.jar Main
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="bash"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java10ACDSBashRun;
