import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ValueExample = () => {
  const javaCode = `
    import org.springframework.beans.factory.annotation.Value;
    import org.springframework.stereotype.Component;

/** In this example, we use the @Value annotation to inject the
 * value of the \${my.property} property from a configuration 
 * file into the myProperty field of the MyComponent class. */

    @Component
    public class MyComponent {
    
        @Value("\${my.property}")
        private String myProperty;
    
        public String getMyProperty() {
            return myProperty;
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ValueExample;
