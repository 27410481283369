import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const JSIntegration = () => {
  const javaCode = `
  import javax.script.ScriptEngine;
  import javax.script.ScriptEngineManager;
  import javax.script.ScriptException;
  
  public class NashornExample {
      public static void main(String[] args) {
          // Creating Nashorn Script Engine
          ScriptEngineManager scriptEngineManager = new ScriptEngineManager();
          ScriptEngine nashorn = scriptEngineManager.getEngineByName("nashorn");
  
          try {
              // Executing Simple JavaScript Code
              nashorn.eval("print('Hello, Nashorn!');");
  
              // Passing Java Objects to JavaScript
              nashorn.put("javaVariable", "Java Variable Value");
              nashorn.eval("print(javaVariable);");
  
              // Invoking JavaScript Functions from Java
              nashorn.eval("function greet(name) { return 'Hello, ' + name; }");
              Object result = nashorn.eval("greet('Nashorn');");
              System.out.println(result); // Output: Hello, Nashorn
  
          } catch (ScriptException e) {
              e.printStackTrace();
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default JSIntegration;
