import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const JavaCodeRest = () => {
  const javaCode = `
    @RestController
    @RequestMapping("/api")
    public class UserController {
        @Autowired
        private UserService userService;
    
        @GetMapping("/users")
        public List<User> getAllUsers() {
            return userService.getAllUsers();
        }
    
        @PostMapping("/users")
        public ResponseEntity<User> createUser(@RequestBody User user) {
            User savedUser = userService.createUser(user);
            return ResponseEntity.status(HttpStatus.CREATED).body(savedUser);
        }
    }
    
    @Service
    public class UserService {
        private List<User> users = new ArrayList<>();
    
        public List<User> getAllUsers() {
            return users;
        }
    
        public User createUser(User user) {
            users.add(user);
            return user;
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default JavaCodeRest;
