import React from "react";
import OptionalClassExample from "./codes/optionalclass/MethodReferenceExample";

const notesOptionalClass = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesOptional.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Optional Class</h4>
        <p>
          The Optional class in Java 8 is designed to address the problem of
          null values and potential null pointer exceptions. It provides a
          container object that may or may not contain a non-null value. The
          main benefits of using Optional include better expressiveness,
          increased safety, and more explicit handling of null values.
        </p>
        <ul>
          <li>
            <h5>Avoidance of Null Pointer Exceptions:</h5>
            <b>Benefit:</b> Helps prevent null pointer exceptions by explicitly
            representing the absence of a value.
            <br />
            <b>Advantage:</b> Code becomes more robust, and developers are
            forced to handle the absence of values more consciously.
          </li>

          <li>
            <h5>Expressiveness:</h5>
            <b>Benefit:</b> Provides a clear and expressive way to represent the
            possibility of a value being absent.
            <br />
            <b>Advantage:</b> Enhances code readability and makes the intention
            of the code more explicit.
          </li>

          <li>
            <h5>Encourages Explicit Handling:</h5>
            <b>Benefit:</b> Encourages developers to explicitly handle cases
            where a value might be absent.
            <br />
            <b>Advantage:</b> Reduces the chances of overlooking null checks and
            improves code reliability.
          </li>
        </ul>
        <p>Examples:</p>
        <OptionalClassExample />
      </div>
    </div>
  );
};
export default notesOptionalClass;
