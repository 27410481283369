import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Repeatable = () => {
  const javaCode = `
  import java.lang.annotation.Repeatable;
  import java.lang.annotation.Retention;
  import java.lang.annotation.RetentionPolicy;

  // Step 1: Define a Repeatable Annotation
  @Repeatable(Greetings.class)
  @Retention(RetentionPolicy.RUNTIME)
  @interface Greeting {
      String value();
  }

  // Step 2: Define a Container Annotation (Optional)
  @Retention(RetentionPolicy.RUNTIME)
  @interface Greetings {
      Greeting[] value();
  }

  // Step 3: Use the Repeatable Annotation
  public class RepeatableAnnotationExample {

      // Applying Repeatable Annotation
      @Greeting("Hello")
      @Greeting("Bonjour")
      public void greet() {
          // Method Implementation
      }

      public static void main(String[] args) {
          // Retrieving Annotations at Runtime
          Greeting[] greetings = RepeatableAnnotationExample.class
                  .getMethod("greet")
                  .getAnnotationsByType(Greeting.class);

          // Displaying Values
          for (Greeting greeting : greetings) {
              System.out.println("Greeting: " + greeting.value());
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Repeatable;
