import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const HTTPClient = () => {
  const javaCode = `
  // Code Example
  import java.net.URI;
  import java.net.http.HttpClient;
  import java.net.http.HttpRequest;
  import java.net.http.HttpResponse;

  public class Http2ClientExample {
      public static void main(String[] args) throws Exception {
          // Create an HTTP/2 client
          HttpClient client = HttpClient.newHttpClient();

          // Create an HTTP request
          HttpRequest request = HttpRequest.newBuilder()
                  .uri(new URI("https://example.com"))
                  .build();

          // Send the request and retrieve the response
          HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());

          // Print the response body
          System.out.println("Response Code: " + response.statusCode());
          System.out.println("Response Body: " + response.body());
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default HTTPClient;
