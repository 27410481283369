import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const LookupExample = () => {
  const javaCode = `
    import org.springframework.beans.factory.annotation.Lookup;
    
/** In this example, we use the @Lookup annotation to indicate 
 * that the getPrototypeBean() method in the MySingletonBean 
 * class should be overridden by a container-generated subclass. 
 * This allows you to obtain a new prototype-scoped instance of 
 * MyPrototypeBean every time the getPrototypeBean() method is 
 * called within MySingletonBean. */

    public interface MyPrototypeBean {
        void doSomething();
    }
    
    public class MySingletonBean {
        @Lookup
        public MyPrototypeBean getPrototypeBean() {
            // This method will be overridden by a container-generated subclass.
            return null;
        }
        
        public void someBusinessLogic() {
            MyPrototypeBean prototypeBean = getPrototypeBean();
            prototypeBean.doSomething();
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default LookupExample;
