import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisHash = () => {
  const javaCode = `import org.springframework.data.annotation.Id;
    import org.springframework.data.redis.core.RedisHash;
    
    @RedisHash("user")
    public class User {
        @Id
        private String id;
        private String name;
        private int age;
        private String email;
        // Other fields and getters/setters...
    }`;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisHash;
