import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/aos.css";
import "./assets/animated.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/elegant-icons/style.css";
import "../node_modules/et-line/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import "./assets/style.scss";
import App from "./main";
import reportWebVitals from "./reportWebVitals";
import Snowfall from "react-snowfall";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/*Snowfall mode */
    /* <div
      style={{
        top: 0,
        left: 0,
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(240, 240, 240, 0.0)",
        position: "fixed",
        zIndex: 1000,
        pointerEvents: "none",
      }}
    >
      <Snowfall />
    </div> */}
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
