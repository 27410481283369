import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RetentionPolicy = () => {
  const javaCode = `
  import java.lang.annotation.Retention;
  import java.lang.annotation.RetentionPolicy;

  // Annotation with RetentionPolicy.SOURCE
  @Retention(RetentionPolicy.SOURCE)
  @interface SourceAnnotation {
      String value() default "SOURCE";
  }

  // Annotation with RetentionPolicy.CLASS
  @Retention(RetentionPolicy.CLASS)
  @interface ClassAnnotation {
      String value() default "CLASS";
  }

  // Annotation with RetentionPolicy.RUNTIME
  @Retention(RetentionPolicy.RUNTIME)
  @interface RuntimeAnnotation {
      String value() default "RUNTIME";
  }

  public class AnnotationExample {

      // Method with annotations
      @SourceAnnotation
      @ClassAnnotation
      @RuntimeAnnotation
      public static void exampleMethod() {
          // Method implementation
      }

      public static void main(String[] args) {
          // Access annotations at runtime
          SourceAnnotation sourceAnnotation = AnnotationExample.class.getAnnotation(SourceAnnotation.class);
          ClassAnnotation classAnnotation = AnnotationExample.class.getAnnotation(ClassAnnotation.class);
          RuntimeAnnotation runtimeAnnotation = AnnotationExample.class.getAnnotation(RuntimeAnnotation.class);

          // Print values from annotations
          System.out.println("Source Annotation: " + sourceAnnotation.value());
          System.out.println("Class Annotation: " + classAnnotation.value());
          System.out.println("Runtime Annotation: " + runtimeAnnotation.value());
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RetentionPolicy;
