import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ComponentScanExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.ComponentScan;
    import org.springframework.context.annotation.Configuration;

/** In this example, we use the @ComponentScan annotation to 
 * specify the base package "com.example.package" where Spring 
 * should scan for components, such as @Component, @Service, 
 * @Repository, etc. */

    @Configuration
    @ComponentScan("com.example.package")
    public class AppConfig {
      // Configuration class with ComponentScan
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ComponentScanExample;
