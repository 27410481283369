import React from "react";

const notesGateway = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesGateway.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>
        Here's a comparison of Netflix Zuul and Kong, highlighting their
        advantages and disadvantages:
      </h4>
      <div className="post-text">
        <h4>1. Netflix Zuul:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Integrates Well with Spring Cloud:</b> If you are already using
            Spring Boot and Spring Cloud for your microservices, Zuul is a
            natural fit. It provides seamless integration with Spring-based
            applications.
          </li>
          <li>
            <b>Netflix Ecosystem:</b> Zuul is part of the Netflix OSS ecosystem,
            which includes tools like Eureka for service discovery and Ribbon
            for client-side load balancing. If you're using other Netflix OSS
            components, Zuul can complement your stack.
          </li>
          <li>
            <b>Custom Filters:</b> Zuul allows you to create custom filters to
            handle various aspects of request and response processing. This
            flexibility can be beneficial for implementing custom security,
            logging, and monitoring logic.
          </li>
          <li>
            <b>Actively Developed:</b> While Netflix has shifted its focus away
            from OSS projects, the community around Zuul is still active, and
            there are ongoing updates and improvements.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Limited Built-in Security:</b> Zuul provides basic security
            features like rate limiting and SSL termination but lacks
            comprehensive built-in authentication and authorization
            capabilities, requiring additional components.
          </li>
          <li>
            <b>Scalability Challenges:</b> As a traditional servlet-based proxy,
            Zuul may face scalability challenges in highly dynamic and
            large-scale environments. This can be mitigated by using
            technologies like Spring Cloud Gateway or Kong for better
            scalability.
          </li>
          <li>
            <b>Ecosystem Dependency:</b> Zuul's integration with the Netflix OSS
            ecosystem might make it less suitable if you prefer to use other
            tools and technologies for service discovery, load balancing, or API
            management.
          </li>
        </ul>
        <br />
        <h4>2. Kong:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Feature-Rich API Management:</b> Kong is designed specifically
            for API management and offers a wide range of features, including
            authentication, authorization, rate limiting, and plugins for
            extending functionality.
          </li>
          <li>
            <b>Plugin System:</b> Kong's plugin system allows you to easily
            extend and customize its functionality to meet your specific
            requirements. There are numerous community-contributed plugins
            available.
          </li>
          <li>
            <b>Scalability:</b> Kong is built to be highly scalable and
            performant, making it a good choice for handling large volumes of
            API traffic.
          </li>
          <li>
            <b>Separation of Concerns:</b> Kong's architecture encourages a
            separation of concerns, which can be advantageous for complex API
            setups. It acts as an intermediary between clients and
            microservices, allowing for centralized control.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Learning Curve:</b> Due to its feature-rich nature, Kong may have
            a steeper learning curve compared to simpler proxy solutions like
            Zuul, especially if you're new to API gateways.
          </li>
          <li>
            <b>Complex Configuration:</b> Configuring Kong with all its plugins
            and features can be complex, especially for beginners. However, it
            provides great flexibility once set up correctly.
          </li>
          <li>
            <b>Community vs. Enterprise:</b> Kong has both open-source
            (Community) and commercial (Enterprise) versions. Some advanced
            features may only be available in the Enterprise edition, which
            could be a consideration for larger organizations.
          </li>
        </ul>
        <p>To summarise,</p>
        <p>
          <b>Netflix Zuul</b> is a good choice for simple API gateway
          deployments. It is easy to use and set up, and it supports a variety
          of features. However, it is not as feature-rich or well-supported as
          some other API gateways.
        </p>
        <p>
          <b>Kong</b> is a good choice for more complex API gateway deployments.
          It is highly extensible and customizable, and it supports a wide range
          of features. It is also well-supported by a large community. However,
          it is more complex to set up and use than Netflix Zuul.
        </p>
      </div>
    </div>
  );
};
export default notesGateway;
