import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisAccess = () => {
  const javaCode = `
    // Publisher
    @Autowired
    private StringRedisTemplate stringRedisTemplate;
    
    public void publishMessage(String channel, String message) {
        stringRedisTemplate.convertAndSend(channel, message);
    }
    // Subscriber
    @RedisListener(channels = "channel_name")
    public void handleMessage(String message) {
        // Handle real-time updates
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisAccess;
