import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisDataModel = () => {
  const javaCode = `
    @RedisHash("User")
    public class User {
        @Id
        private String id;
        private String username;
        private String email;
        
        // Getters and setters
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisDataModel;
