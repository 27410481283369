import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisMemoryManagement = () => {
  const javaCode = `
    @Value("\${spring.redis.host}")
    private String redisHost;
    
    @Value("\${spring.redis.port}")
    private int redisPort;
    
    public long getMemoryUsage(String key) {
        RedisConnection connection = new LettuceConnectionFactory(redisHost, redisPort).getConnection();
        RedisCommands<String, String> commands = connection.sync();
    
        Long memoryUsage = commands.memoryUsage(key);
        connection.close();
        
        return memoryUsage;
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisMemoryManagement;
