import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8StreamsAPI = () => {
  const javaCode = `
// Streams API: Streams provide a powerful and flexible way to process collections of data. You can perform operations such as filtering, mapping, and reducing on collections in a declarative way.
// Using Streams to filter and print even numbers
List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
numbers.stream()
       .filter(n -> n % 2 == 0)
       .forEach(System.out::println);
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8StreamsAPI;
