import React from "react";
import StreamAPIDeclarative from "./codes/stream/StreamAPIDeclarative";
import StreamAPIParallel from "./codes/stream/StreamAPIParallel";
import StreamAPILazy from "./codes/stream/StreamAPILazy";
import StreamAPIFunctional from "./codes/stream/StreamAPIFunctional";
import StreamAPIReadability from "./codes/stream/StreamAPIReadability";
import StreamAPIFlexibility from "./codes/stream/StreamAPIFlexibility";
import StreamAPIStatelessness from "./codes/stream/StreamAPIStatelessness";
import StreamAPIOperations from "./codes/stream/StreamAPIOperations";
import StreamAPIReduction from "./codes/stream/StreamAPIReduction";
import StreamAPIFunctionalInterfaces from "./codes/stream/StreamAPIFunctionalInterfaces";
import StreamAPIStream from "./codes/stream/StreamAPIStream";
import StreamAPIAbsence from "./codes/stream/StreamAPIAbsence";
import StreamAPIChaining from "./codes/stream/StreamAPIChaining";
import StreamAPIAggregation from "./codes/stream/StreamAPIAggregation";
import StreamAPIImmutable from "./codes/stream/StreamAPIImmutable";

const notesLambdaExpression = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesStreamAPI.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Streams API</h4>
        <p>
          Java 8 Stream API provides a powerful and expressive way to process
          collections of data in a functional style. Streams allow to perform
          operations on a collection, such as filtering, mapping, and reducing,
          using a more concise and declarative syntax. Brings several benefits
          and introduces a paradigm shift in the way data processing is handled
          compared to traditional iterative approaches.
        </p>
        <ul>
          <li>
            <h5>1. Declarative Style:</h5>
            <b>Benefit:</b> Streams promote a declarative programming style
            where you describe what you want to achieve rather than how to
            achieve it.
            <br />
            <b>Advantage:</b> Code becomes more expressive, readable, and closer
            to the problem domain.
            <StreamAPIDeclarative />
          </li>

          <li>
            <h5>2. Parallel Processing:</h5>
            <b>Benefit:</b> Streams provide an easy way to parallelize
            operations, allowing for efficient utilization of multi-core
            processors.
            <br />
            <b>Advantage:</b> Improved performance on multicore systems without
            the need for explicit thread management.
            <StreamAPIParallel />
          </li>

          <li>
            <h5>3. Lazy Evaluation:</h5>
            <b>Benefit:</b> Operations on streams are evaluated only when a
            terminal operation is invoked.
            <br />
            <b>Advantage:</b> Enhances efficiency by avoiding unnecessary
            computation and allows for more optimized processing.
            <StreamAPILazy />
          </li>

          <li>
            <h5>4. Functional Composition:</h5>
            <b>Benefit:</b> Stream operations can be easily composed, enabling
            the creation of complex data transformations.
            <br />
            <b>Advantage:</b> Supports functional programming principles,
            leading to more modular and reusable code.
            <StreamAPIFunctional />
          </li>

          <li>
            <h5>5. Readability and Conciseness:</h5>
            <b>Benefit:</b> Stream operations are concise and often require less
            code compared to traditional iterative approaches.
            <br />
            <b>Advantage:</b> Code is more readable, reducing boilerplate and
            making it easier to understand. <StreamAPIReadability />
          </li>

          <li>
            <h5>6. Flexibility with Data Sources:</h5>
            <b>Benefit:</b> Streams can be created from various sources,
            including collections, arrays, I/O channels, and generator
            functions.
            <br />
            <b>Advantage:</b> Provides a unified API for processing different
            types of data sources.
            <StreamAPIFlexibility />
          </li>

          <li>
            <h5>7. Statelessness:</h5>
            <b>Benefit:</b> Stream operations are designed to be stateless and
            not alter the underlying data source.
            <br />
            <b>Advantage:</b> Easier to reason about and facilitates parallel
            processing without concerns about shared state.{" "}
            <StreamAPIStatelessness />
          </li>

          <li>
            <h5>8. Intermediate and Terminal Operations:</h5>
            <b>Benefit:</b> Stream operations are categorized into intermediate
            and terminal operations, promoting a clear separation of concerns.
            <br />
            <b>Advantage:</b> Enhances code modularity, allowing for better
            organization and understanding. <StreamAPIOperations />
          </li>

          <li>
            <h5>9. Reduction Operations:</h5>
            <b>Benefit:</b> Stream API includes powerful reduction operations
            like reduce, collect, and summarizing.
            <br />
            <b>Advantage:</b> Simplifies common aggregation tasks, making it
            easier to express complex computations. <StreamAPIReduction />
          </li>

          <li>
            <h5>10. Integration with Functional Interfaces:</h5>
            <b>Benefit:</b> Stream API leverages functional interfaces from the
            java.util.function package, such as Predicate, Function, and
            Consumer.
            <br />
            <b>Advantage:</b> Enables seamless integration with lambda
            expressions, promoting a more functional programming style.
            <StreamAPIFunctionalInterfaces />
          </li>

          <li>
            <h5>11. Parallel Stream Support:</h5>
            <b>Benefit:</b> Streams can easily be converted to parallel streams
            for parallel processing.
            <br />
            <b>Advantage:</b> Simplifies parallelization of operations without
            explicit threading. <StreamAPIStream />
          </li>

          <li>
            <h5>12. Optional for Absence of Values:</h5>
            <b>Benefit:</b> Stream operations like findFirst and max/min return
            Optional to handle potential absence of values.
            <br />
            <b>Advantage:</b> Provides a clean and type-safe way to handle
            optional results. <StreamAPIAbsence />
          </li>

          <li>
            <h5>13. Method Chaining:</h5>
            <b>Benefit:</b> Stream operations can be chained together, enabling
            a fluent and expressive coding style.
            <br />
            <b>Advantage:</b> Improves code readability and makes it easier to
            understand complex data transformations. <StreamAPIChaining />
          </li>

          <li>
            <h5>14. Collectors for Aggregation:</h5>
            <b>Benefit:</b> The Collectors utility class provides a set of
            predefined collectors for common aggregation tasks.
            <br />
            <b>Advantage:</b> Streamlines the process of collecting and
            aggregating results. <StreamAPIAggregation />
          </li>

          <li>
            <h5>15. Immutable Collections:</h5>
            <b>Benefit:</b> Many stream operations return immutable collections,
            ensuring thread safety and data integrity.
            <br />
            <b>Advantage:</b> Reduces the likelihood of unintended side effects
            and simplifies concurrent processing. <StreamAPIImmutable />
          </li>
        </ul>
      </div>
    </div>
  );
};
export default notesLambdaExpression;
