import React from "react";
import IPAPI from "./codes/ipapi/IPAPI";

const notesIPAPI = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesIPAPI.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 9 key features</h4>
      <div className="post-text">
        <ul>
          <li>
            <strong>Java 9 Improved Process API:</strong>
            <p>
              Java 9 introduced enhancements to the Process API, providing a
              more flexible and powerful way to interact with and manage native
              processes. It aims to improve the management and monitoring of
              external processes launched from Java applications.
            </p>
          </li>
          <li>
            <strong>Benefits of Improved Process API:</strong>
            <ol>
              <li>
                <strong>Enhanced Process Handling:</strong> The Improved Process
                API simplifies and enhances the handling of native processes,
                making it easier to start, manage, and interact with them.
              </li>
              <li>
                <strong>Access to Process Information:</strong> It provides
                better access to process information, including the PID (Process
                ID), exit code, and other relevant details.
              </li>
              <li>
                <strong>Redirecting I/O Streams:</strong> The API supports the
                redirection of standard input, output, and error streams of the
                launched processes.
              </li>
              <li>
                <strong>Destroying Processes:</strong> The API allows more
                controlled and graceful termination of processes with additional
                methods like `destroyForcibly()`.
              </li>
              <li>
                <strong>Process Timeout:</strong> Improved support for setting
                timeouts when waiting for a process to complete, enabling better
                handling of long-running processes.
              </li>
            </ol>
          </li>
          <li>
            <p>Example:</p>
            <IPAPI />
          </li>
          <li>
            <strong>What's New:</strong>
            <p>
              The Improved Process API introduces additional methods and
              features, providing better control and information retrieval
              capabilities when working with external processes.
            </p>
          </li>
          <li>
            <strong>Unique Benefits:</strong>
            <p>
              This enhancement to the Process API brings improved control,
              monitoring, and information retrieval for external processes,
              making it easier for Java applications to interact with the
              underlying system processes.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default notesIPAPI;
