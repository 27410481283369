import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RoleExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Role;
    
/**In this example, we use the @Role annotation to specify 
 * the role hint for a specific bean definition. The ROLE_INFRASTRUCTURE 
 * constant from BeanDefinition is used to indicate that the bean plays 
 * an infrastructure role in the Spring application context.*/

    @Configuration
    public class MyConfig {
    
        @Bean
        @Role(BeanDefinition.ROLE_INFRASTRUCTURE)
        public MyBean myBean() {
            return new MyBean();
        }
    }
    
    public class MyBean {
        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RoleExample;
