import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ValidatedExample = () => {
  const javaCode = `
    import org.springframework.stereotype.Component;
    import org.springframework.validation.annotation.Validated;

/** In this example, we use the @Validated annotation to enable 
 * method-level validation using JSR-303 annotations like @NotNull. 
 * This allows you to validate the input parameters of methods 
 * within the MyComponent class. */

    @Component
    @Validated
    public class MyComponent {
    
        public void doSomething(@javax.validation.constraints.NotNull String input) {
            // Business logic here
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ValidatedExample;
