import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ConfigurationPropertiesExample = () => {
  const javaCode = `
    import org.springframework.boot.context.properties.ConfigurationProperties;
    import org.springframework.stereotype.Component;

/** n this example, we use the @ConfigurationProperties annotation to bind 
 * properties with the prefix "myapp" from a configuration file to fields 
 * in the MyConfigurationProperties class. */

    @Component
    @ConfigurationProperties(prefix = "myapp")
    public class MyConfigurationProperties {
    
        private String property1;
        private int property2;
        
        // Getters and setters
        
        public String getProperty1() {
            return property1;
        }
        
        public void setProperty1(String property1) {
            this.property1 = property1;
        }
        
        public int getProperty2() {
            return property2;
        }
        
        public void setProperty2(int property2) {
            this.property2 = property2;
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ConfigurationPropertiesExample;
