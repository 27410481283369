import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java10Var = () => {
  const javaCode = `
  import java.util.ArrayList;
  import java.util.HashMap;
  import java.util.List;
  import java.util.Map;

  public class VarExample {

      public static void main(String[] args) {
          // Example 1: Inferring type for a String
          var greeting = "Hello, World!";
          System.out.println(greeting);

          // Example 2: Inferring type for an ArrayList
          var numbers = new ArrayList<Integer>();
          numbers.add(42);
          numbers.add(101);
          System.out.println(numbers);

          // Example 3: Inferring type for a HashMap
          var personInfo = new HashMap<String, String>();
          personInfo.put("name", "John Doe");
          personInfo.put("occupation", "Software Engineer");
          System.out.println(personInfo);

          // Example 4: Enhanced for loop with var
          for (var entry : personInfo.entrySet()) {
              System.out.println(entry.getKey() + ": " + entry.getValue());
          }

          // Example 5: Var in lambda expressions
          var square = (int x) -> x * x;
          System.out.println("Square of 5: " + square.applyAsInt(5));
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java10Var;
