import React from "react";
import DateAndTimeAPIExample from "./codes/dateandtimeapi/DateAndTimeAPIExample";

const notesDateAndTimeAPI = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesDateAndTime.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Date and Time API</h4>
        <p>
          The Java 8 Date and Time API, located in the java.time package, was
          introduced to address the shortcomings of the older java.util.Date and
          java.util.Calendar classes. The new API is more comprehensive,
          flexible, and follows modern design principles.
        </p>
        <ul>
          <li>
            <h5>Immutability:</h5>
            <b>Benefit:</b> Date and time objects are immutable, ensuring thread
            safety and preventing unintended side effects.
            <br />
            <b>Advantage:</b> Simplifies code and promotes better concurrency.
          </li>

          <li>
            <h5>Clarity and Readability:</h5>
            <b>Benefit:</b> The API provides classes with clear and
            self-explanatory names, enhancing code readability.
            <br />
            <b>Advantage:</b> Makes it easier to understand and reason about
            date and time-related code.
          </li>

          <li>
            <h5>Thread Safety:</h5>
            <b>Benefit:</b> The API is designed to be thread-safe, eliminating
            synchronization concerns.
            <br />
            <b>Advantage:</b> Facilitates concurrent programming without the
            need for external synchronization.
          </li>

          <li>
            <h5>Improved Date and Time Classes:</h5>
            <b>Benefit:</b> Introduces new classes like LocalDate, LocalTime,
            and LocalDateTime to represent date and time components without time
            zone information.
            <br />
            <b>Advantage:</b> Provides more flexibility and precision for
            various use cases.
          </li>

          <li>
            <h5>Time Zone Support:</h5>
            <b>Benefit:</b> The API includes classes like ZoneId and ZoneOffset
            to handle time zones more effectively.
            <br />
            <b>Advantage:</b> Enables accurate representation and manipulation
            of dates and times in different time zones.
          </li>

          <li>
            <h5>ISO-8601 Standard Compliance:</h5>
            <b>Benefit:</b> The API follows the ISO-8601 standard for date and
            time representation.
            <br />
            <b>Advantage:</b> Enhances interoperability and consistency in date
            and time handling.
          </li>

          <li>
            <h5>Temporal Adjusters and Queries:</h5>
            <b>Benefit:</b> Introduces the concept of temporal adjusters and
            queries for more sophisticated date and time manipulations.
            <br />
            <b>Advantage:</b> Allows developers to perform complex operations,
            such as finding the next working day or the last day of the month.
          </li>

          <li>
            <h5>Parsing and Formatting:</h5>
            <b>Benefit:</b> Provides a flexible and powerful parsing and
            formatting API
            <br />
            <b>Advantage:</b> Simplifies the conversion of date and time strings
            to objects and vice versa.
          </li>
        </ul>
        <p>Examples:</p>
        <DateAndTimeAPIExample />
      </div>
    </div>
  );
};
export default notesDateAndTimeAPI;
