import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPILazy = () => {
  const javaCode = `
  List<String> names = Arrays.asList("Alice", "Bob", "Charlie");

  // Lazy Evaluation
  Stream<String> nameStream = names.stream().filter(name -> name.startsWith("A"));
  
  // Terminal Operation triggers evaluation
  long count = nameStream.count();  
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPILazy;
