import React from "react";
import JShell from "./codes/jshell/JShell";

const notesJShell = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesJShell.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 9 key features</h4>
      <div className="post-text">
        <ul>
          <li>
            <strong>Java 9 JShell (Interactive Shell):</strong>
            <p>
              JShell is an interactive REPL (Read-Eval-Print Loop) introduced in
              Java 9. It provides a way to interactively evaluate Java code
              snippets without the need for compilation.
            </p>
          </li>
          <li>
            <p>Examples:</p>
            <JShell />
          </li>
          <li>
            <strong>Benefits of JShell:</strong>
            <ol>
              <li>
                <strong>Interactive Exploration:</strong> JShell allows
                developers to interactively explore and test Java code snippets
                without the need for creating a complete Java program.
              </li>
              <li>
                <strong>Rapid Prototyping:</strong> It facilitates rapid
                prototyping and experimentation, enabling quick feedback during
                development.
              </li>
              <li>
                <strong>Learning and Teaching:</strong> JShell is an excellent
                tool for learning Java, trying out new features, and teaching
                programming concepts.
              </li>
              <li>
                <strong>Debugging:</strong> Developers can use JShell for quick
                debugging and validation of small code snippets.
              </li>
            </ol>
          </li>
          <li>
            <strong>What's New:</strong>
            <p>
              JShell brings a new level of interactivity to Java development,
              allowing developers to experiment with code in real-time. It
              introduces a lightweight and accessible tool for code exploration
              and testing.
            </p>
          </li>
          <li>
            <strong>Unique Benefits:</strong>
            <p>
              JShell's unique benefits include its ability to provide instant
              feedback, eliminate the need for a separate compilation step, and
              offer a seamless interactive environment for Java developers.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default notesJShell;
