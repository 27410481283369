import { Container, Paper } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Set the worker URL for PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function CV() {
  const getScaleForScreen = () => {
    const screenSize = window.innerWidth;

    if (screenSize < 600) {
      // Small screens (e.g., mobile devices)
      return 0.6;
    } else if (screenSize >= 600 && screenSize < 960) {
      // Medium screens (e.g., tablets)
      return 1.3;
    } else {
      // Large screens (e.g., desktop)
      return 1.4;
    }
  };
  return (
    <Container maxWidth="md">
      <Paper elevation={15}>
        <Document
          file={"docs/tosunufuk.pdf"}
          loading={<div>Loading PDF...</div>}
        >
          <Page
            pageNumber={1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={getScaleForScreen()}
          />
          <Page
            pageNumber={2}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            scale={getScaleForScreen()}
          />
        </Document>
      </Paper>
    </Container>
  );
}
