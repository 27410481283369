import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java10ACDS = () => {
  const javaCode = `
  // Main.java
  public class Main {
      public static void main(String[] args) {
          System.out.println("Hello, AppCDS!");

          // Create an instance of the shared class
          SharedClass sharedInstance = new SharedClass();
          sharedInstance.printMessage();
      }
  }

  // SharedClass.java
  public class SharedClass {
      public void printMessage() {
          System.out.println("This is a message from the shared class!");
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java10ACDS;
