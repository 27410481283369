import React from "react";
import RedisQueryObjects from "./codes/redis/RedisQueryObjects";
import RedisQueryObjectsSimple from "./codes/redis/RedisQueryObjectsSimple";
import RedisHash from "./codes/redis/RedisHash";

function notesRedisQueryArticle() {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesRedisQuery.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">January 1, 2019</span>
        <span className="post-like">200</span>
        <span className="post-comment">8</span>
      </div>

      <h4>Redis Query</h4>
      <div className="post-text">
        <h5>Redis Query for Complex Objects in Hashsets with Spring Redis</h5>
        <p>
          Redis, renowned for its high-performance key-value data store
          capabilities, can also serve as a powerful tool for querying complex
          objects stored within hashsets. This article delves deep into the art
          of efficiently querying Redis hashsets, where each key-value pair
          holds a complex object with multiple fields. Additionally, we'll
          explore two Redis modules, Redisearch and RedisJSON, providing a
          detailed comparison of their usages, advantages, disadvantages, and
          opportunities.
        </p>
        <h5>Storing Complex Objects in Redis Hashsets</h5>
        <p>
          Redis hashsets prove to be a versatile data structure that excels at
          storing multiple fields and their corresponding values under a single
          key. This versatility makes them an ideal choice for representing
          complex objects. Consider the following complex objects:
        </p>
        <RedisQueryObjects />
        <p>
          In Redis, these objects can be stored within hashsets using the{" "}
          <strong>HSET</strong> command, similar to the previous example.
        </p>
        <h5>Querying Redis Hashsets</h5>
        <p>
          Redis offers a plethora of commands for querying hashsets. Below are
          some common use cases:
        </p>
        <h5>Retrieving a Single Field</h5>
        <p>
          To retrieve a specific field from a hashset, you can utilize the{" "}
          <strong>HGET</strong>
          command. For instance, to obtain the email of user{" "}
          <strong>12345:</strong>
        </p>
        <code>HGET user:12345 email</code>
        <p>
          This command will return the email address associated with the user.
        </p>
        <h5>Retrieving a Multiple Field</h5>
        <p>
          For fetching multiple fields simultaneously, the{" "}
          <strong>HMGET</strong> command proves invaluable. It allows you to
          obtain values for several fields in a single query. For example:
        </p>
        <code>HMGET user:12345 name age</code>
        <p>
          This command will return an array with the values of{" "}
          <strong>name</strong> and <strong>age</strong>.
        </p>
        <h5>Querying by Object ID</h5>
        <p>
          If you possess knowledge of the object's ID (e.g.,{" "}
          <strong>12345</strong>), you can directly access it by using the
          hashset key:
        </p>
        <code>HGETALL user:12345</code>
        <p>
          This retrieves all fields and their corresponding values for the
          specified object.
        </p>
        <h5>Querying Redis Hashsets with OR operator</h5>
        <p>
          To retrieve users with age <strong>29</strong> or <strong>30</strong>{" "}
          from the hashset-based complex objects, you can use the{" "}
          <strong>HSCAN</strong> command to iterate through all hashsets and
          filter based on your criteria. Here's an example:
        </p>
        <code>HSCAN 0 MATCH age 29|30 COUNT 10</code>
        <p>
          This command will search all hashsets for users with an age of{" "}
          <strong>29</strong> or <strong>30</strong>. You can adjust the COUNT
          parameter to control the number of results returned.
        </p>
        <h5>Querying Redis JSON</h5>
        <p>
          Querying JSON documents in Redis is straightforward, thanks to its
          native JSON support. Redis offers the <strong>JSON.GET</strong>{" "}
          command to retrieve JSON values. Let's see how to query our
          JSON-stored complex objects:
        </p>
        <h5>Retrieving a JSON Document</h5>
        <p>
          To retrieve the JSON document for user 12345, you can use the JSON.GET
          command:
        </p>
        <code>JSON.GET user:12345</code>
        <p>
          This command returns the entire JSON document associated with the
          specified key.
        </p>{" "}
        <h5>Retrieving a Specific Field from JSON</h5>
        <p>
          If you want to retrieve a specific field from the JSON document, you
          can use the JSON.GET command with a path specifier. For instance, to
          obtain the user's email:
        </p>
        <code>JSON.GET user:12345 .email</code>
        <p>
          This command returns the email value from the JSON document. Redis
          offers extensive capabilities for querying JSON documents, including
          filtering, extraction of specific fields, and more.
        </p>
        <h5>Querying Redis JSON for Age 29 or 30</h5>
        <p>
          To retrieve users with age 29 or 30 from the JSON-based complex
          objects, you can use the JSON.QUERY command with a JSONPath
          expression. Here's an example:
        </p>
        <code>JSON.QUERY user:* '$.age == 29 || $.age == 30'</code>
        <p>
          This command will query all JSON documents for users with an age of 29
          or 30. These commands will help you filter and retrieve users based on
          age criteria for both hashsets and JSON-stored complex objects in
          Redis.
        </p>
        <h4>Redis Modules: Redisearch vs. RedisJSON</h4>
        <p>
          Now that we've covered basic Redis hashset queries, it's time to delve
          into two Redis modules, Redisearch and RedisJSON, and conduct a
          comprehensive comparison of their usages, advantages, disadvantages,
          and opportunities for handling complex objects.
        </p>
        <h3>Redisearch Module</h3>
        <h5>Usages</h5>
        <p>
          Redisearch stands as an advanced full-text search and indexing module
          meticulously crafted for Redis. While it's not specifically designed
          for complex object storage, it excels at indexing and searching text
          fields within your complex objects.
        </p>
        <h5>Upsides</h5>
        <ul>
          <li>
            <strong>Full-text search capabilities:</strong> Redisearch empowers
            you to execute complex text-based queries, making it an optimal
            choice for searching within textual fields of your complex objects.
          </li>
          <li>
            <strong>High-performance indexing:</strong> Engineered for speed,
            Redisearch efficiently handles large datasets and intricate queries.
          </li>
          <li>
            <strong>Scalability:</strong> Redisearch supports horizontal
            scaling, ensuring peak performance as your data volume expands.
          </li>
        </ul>
        <h5>Downsides</h5>
        <ul>
          <li>
            <strong>Not tailored for complex objects:</strong> Redisearch
            primarily focuses on text search and may not be the most suitable
            solution for querying the structure of complex objects.
          </li>
          <li>
            <strong>Learning curve:</strong> Implementing Redisearch may require
            some additional learning compared to basic Redis hashset queries.
          </li>
        </ul>
        <h5>Opportunities</h5>
        <ul>
          <li>
            <strong>
              Opt for Redisearch when your primary objective is text-based
              searching within complex objects.
            </strong>
          </li>
          <li>
            <strong>
              Redisearch proves beneficial for applications necessitating
              full-text search capabilities, such as e-commerce product searches
              or document indexing.
            </strong>
          </li>
        </ul>
        <h3>RedisJSON Module</h3>
        <h5>Usages</h5>
        <p>
          RedisJSON emerges as a module that seamlessly introduces JSON document
          capabilities into Redis. It facilitates the storage, querying, and
          manipulation of JSON data with exceptional efficiency.
        </p>
        <h5>Upsides</h5>
        <ul>
          <li>
            <strong>Native JSON support:</strong> RedisJSON is tailor-made for
            working with complex objects in JSON format. You can effortlessly
            store and query JSON documents.
          </li>
          <li>
            <strong>Querying flexibility:</strong> It offers a wide spectrum of
            query capabilities, encompassing filtering, extracting specific
            fields, and more.
          </li>
          <li>
            <strong>Seamless integration:</strong> RedisJSON harmoniously
            integrates with existing Redis data and commands.
          </li>
        </ul>
        <h5>Downsides</h5>
        <ul>
          <li>
            <strong>Limited text search capabilities:</strong> While RedisJSON
            excels at querying structured JSON data, it lacks the advanced text
            search capabilities found in Redisearch.
          </li>
          <li>
            <strong>Potential data migration:</strong> If your Redis data is
            already stored in a different format, transitioning to JSON may
            necessitate data migration efforts.
          </li>
        </ul>
        <h5>Opportunities</h5>
        <ul>
          <li>
            <strong>
              Choose RedisJSON when your focus centers on working with
              structured JSON data and complex objects.
            </strong>
          </li>
          <li>
            <strong>
              It proves ideal for applications where JSON is a natural data
              format, such as NoSQL databases, document stores, or configuration
              management systems.
            </strong>
          </li>
        </ul>
        <h3>Querying RedisJSON for Age 29 or 30</h3>
        <p>
          To retrieve users with age 29 or 30 from the JSON-based complex
          objects, you can use the JSON.QUERY command with a JSONPath
          expression. Here's an example:
        </p>
        <code>JSON.QUERY user:* '$.age == 29 || $.age == 30'</code>
        <p>
          This command will query all JSON documents for users with an age of 29
          or 30.
        </p>
        <h5>Usage Examples:</h5>
        <p>Let's assume we have the following JSON-stored complex objects:</p>
        <RedisQueryObjectsSimple />
        <ul>
          <li>
            To retrieve users with age 29 or 30:
            <code>JSON.QUERY user:* '$.age == 29 || $.age == 30'</code>
          </li>
          <li>
            To retrieve users with age 30:
            <code>JSON.QUERY user:* '$.age == 30'</code>
          </li>
          <li>
            To retrieve users with age 29:
            <code>JSON.QUERY user:* '$.age == 29'</code>
          </li>
        </ul>
        <p>
          These commands will help you filter and retrieve users based on age
          criteria from JSON-stored complex objects in Redis.
        </p>
        <h4>Redis Modules: Redisearch vs. RedisJSON</h4>
        <p>
          Now that we've covered basic Redis hashset queries, it's time to delve
          into two Redis modules, Redisearch and RedisJSON, and conduct a
          comprehensive comparison of their usages, advantages, disadvantages,
          and opportunities for handling complex objects.
        </p>
        <h4>Redisearch Module</h4>
        <p>
          <strong>Usages</strong>
        </p>
        <p>
          Redisearch stands as an advanced full-text search and indexing module
          meticulously crafted for Redis. While it's not specifically designed
          for complex object storage, it excels at indexing and searching text
          fields within your complex objects.
        </p>
        <p>
          <strong>Upsides</strong>
        </p>
        <ul>
          <li>
            Full-text search capabilities: Redisearch empowers you to execute
            complex text-based queries, making it an optimal choice for
            searching within textual fields of your complex objects.
          </li>
          <li>
            High-performance indexing: Engineered for speed, Redisearch
            efficiently handles large datasets and intricate queries.
          </li>
          <li>
            Scalability: Redisearch supports horizontal scaling, ensuring peak
            performance as your data volume expands.
          </li>
        </ul>
        <p>
          <strong>Downsides</strong>
        </p>
        <ul>
          <li>
            Not tailored for complex objects: Redisearch primarily focuses on
            text search and may not be the most suitable solution for querying
            the structure of complex objects.
          </li>
          <li>
            Learning curve: Implementing Redisearch may require some additional
            learning compared to basic Redis hashset queries.
          </li>
        </ul>
        <p>
          <strong>Opportunities</strong>
        </p>
        <ul>
          <li>
            Opt for Redisearch when your primary objective is text-based
            searching within complex objects.
          </li>
          <li>
            Redisearch proves beneficial for applications necessitating
            full-text search capabilities, such as e-commerce product searches
            or document indexing.
          </li>
        </ul>
        <h4>RedisJSON Module</h4>
        <p>
          <strong>Usages</strong>
        </p>
        <p>
          RedisJSON emerges as a module that seamlessly introduces JSON document
          capabilities into Redis. It facilitates the storage, querying, and
          manipulation of JSON data with exceptional efficiency.
        </p>
        <p>
          <strong>Upsides</strong>
        </p>
        <ul>
          <li>
            Native JSON support: RedisJSON is tailor-made for working with
            complex objects in JSON format. You can effortlessly store and query
            JSON documents.
          </li>
          <li>
            Querying flexibility: It offers a wide spectrum of query
            capabilities, encompassing filtering, extracting specific fields,
            and more.
          </li>
          <li>
            Seamless integration: RedisJSON harmoniously integrates with
            existing Redis data and commands.
          </li>
        </ul>
        <p>
          <strong>Downsides</strong>
        </p>
        <ul>
          <li>
            Limited text search capabilities: While RedisJSON excels at querying
            structured JSON data, it lacks the advanced text search capabilities
            found in Redisearch.
          </li>
          <li>
            Potential data migration: If your Redis data is already stored in a
            different format, transitioning to JSON may necessitate data
            migration efforts.
          </li>
        </ul>
        <p>
          <strong>Opportunities</strong>
        </p>
        <ul>
          <li>
            Choose RedisJSON when your focus centers on working with structured
            JSON data and complex objects.
          </li>
          <li>
            It proves ideal for applications where JSON is a natural data
            format, such as NoSQL databases, document stores, or configuration
            management systems.
          </li>
        </ul>
        <h4>Using Spring Redis to Convert Hashset ID to JSON ID</h4>
        <p>
          To enhance the organization and structure of your complex objects
          within Redis hashsets, you can leverage Spring Redis and the
          <strong>@RedisHash</strong> annotation. This annotation automatically
          converts the hashset's ID to a JSON ID, simplifying the process of
          managing your data.
        </p>
        <RedisHash />
        <p>
          With this configuration, Spring Redis will automatically handle the
          conversion of the hashset's ID to a JSON ID when you save or retrieve
          objects.{" "}
        </p>
        <h4>Conclusion</h4>
        <p>
          In the realm of querying complex objects within Redis hashsets, you
          possess a powerful arsenal of tools at your disposal. Depending on
          your specific use case and requirements, you can opt for basic Redis
          hashset queries, harness the capabilities of Redis modules like
          Redisearch and RedisJSON, or enhance organization with Spring Redis
          and the @RedisHash annotation.
        </p>
        <ul>
          <li>
            <strong>Redisearch</strong> is your go-to choice when robust
            full-text search capabilities within your objects are required, and
            you can manage the learning curve.
          </li>
          <li>
            <strong>RedisJSON</strong> takes the stage when you seek to handle
            structured JSON data and complex objects seamlessly.
          </li>
          <li>
            <strong>Spring Redis</strong> offers a structured approach to
            managing complex objects within Redis hashsets, enhancing
            organization and simplifying data conversion.
          </li>
        </ul>
        <p>
          Select the Redis approach that aligns best with your data and
          application needs to unlock the full potential of Redis for your
          complex object storage and querying requirements. Whether it's a deep
          dive into text-based searching, seamless manipulation of JSON
          documents, or enhanced data organization, Redis and its modules
          provide the flexibility and versatility to cater to your unique
          demands.
        </p>
      </div>
    </div>
  );
}

export default notesRedisQueryArticle;
