import React from "react";
import AOS from "aos";
AOS.init();

const whatido = () => {
  return (
    <div className="container">
      <div className="row">
        <div
          className="col-md-12 text-center"
          data-aos="fade-up"
          data-aos-delay="0"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <h2>What I Do</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="spacer-double"></div>
      <div className="row">
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="300"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="fa fa-paint-brush id-color-2"></i>
              <div className="text">
                <h3>Design</h3>
                Design applications by prioritizing the consumer's needs for
                both Frontend and Backend. From definition of the purpose and
                scope of the application to planning the development process.
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="400"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="fa fa-code id-color-2"></i>
              <div className="text">
                <h3>Code</h3>
                Write efficient, maintainable, and scalable code that adheres to
                established coding standards and best practices.
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4"
          data-aos="fade"
          data-aos-delay="500"
          data-aos-duration="1000"
          data-aos-easing="ease"
          data-aos-once="true"
        >
          <div className="de_3d-box">
            <div className="d-inner">
              <i className="fa fa-user-o id-color-2"></i>
              <div className="text">
                <h3>Test</h3>
                Create and maintain test plans, test cases and automated test
                scripts to improve testing efficiency and also to ensure
                comprehensive test coverage of the software.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default whatido;
