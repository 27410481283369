import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const PrimaryExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Primary;
    
/** In this example, we use the @Primary annotation to specify 
 * that the bean2() method should be the primary bean to be 
 * injected when there are multiple beans of the same type 
 * (in this case, MyBean). This allows you to indicate a primary 
 * choice when there's ambiguity in bean injection.*/

    @Configuration
    public class MyConfig {
    
        @Bean
        public MyBean bean1() {
            return new MyBean("Bean 1");
        }
        
        @Bean
        @Primary
        public MyBean bean2() {
            return new MyBean("Bean 2 (Primary)");
        }
    }
    
    public class MyBean {
        private String name;
        
        public MyBean(String name) {
            this.name = name;
        }
        
        public String getName() {
            return name;
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default PrimaryExample;
