import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const MethodReferenceConstructor = () => {
  const javaCode = `
    // Lambda Expression
    Supplier<List<String>> listSupplierLambda = () -> new ArrayList<>();
    
    // Method Reference
    Supplier<List<String>> listSupplierReference = ArrayList::new;
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default MethodReferenceConstructor;
