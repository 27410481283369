import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ModuleSystem = () => {
  const javaCode = `
  module com.example.module1 {
    requires com.example.module2;
    exports com.example.module1.api;
  }

  package com.example.module1.api;

  public interface Module1Service {
      void performAction();
  }

  module com.example.module2 {
      exports com.example.module2.api;
  }

  package com.example.module2.api;

  public interface Module2Service {
      void doSomething();
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ModuleSystem;
