import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const LazyExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Lazy;

    /** In this example, we use the @Lazy annotation to indicate 
     * that the lazyBean() method should create a lazily-initialized 
     * bean. This means the bean will only be created when it's 
     * first requested, rather than during the application's startup. */
    
    @Configuration
    public class MyConfig {
    
        @Bean
        @Lazy
        public MyLazyBean lazyBean() {
            return new MyLazyBean();
        }
    }
    
    public class MyLazyBean {
        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default LazyExample;
