import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const PropertySourceExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.PropertySource;

/** In this example, we use the @PropertySource annotation to 
 * specify a properties file (my.properties) located on the 
 * classpath. The properties defined in this file will be 
 * loaded into the Spring application context. */

    @Configuration
    @PropertySource("classpath:my.properties")
    public class MyConfig {
    
        // Configuration logic here
        
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default PropertySourceExample;
