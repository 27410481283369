import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8FunctionalInterfaceBinaryOperator = () => {
  const javaCode = `
  BinaryOperator<Integer> add = (a, b) -> a + b;
  int result = add.apply(3, 4); // Result: 7
   `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8FunctionalInterfaceBinaryOperator;
