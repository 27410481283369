import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8Optional = () => {
  const javaCode = `
// Optional: The Optional class was introduced to deal with null values more effectively and avoid null pointer exceptions.
// Using Optional to handle potential null value
String name = null;
Optional<String> optionalName = Optional.ofNullable(name);
if (optionalName.isPresent()) {
    System.out.println("Name: " + optionalName.get());
} else {
    System.out.println("Name is not present.");
}
 `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8Optional;
