import React from "react";
import ModuleSystemDeclaration from "./codes/modulesystem/ModuleSystemDeclaration";
import ModuleSystemRequiresExports from "./codes/modulesystem/ModuleSystemRequiresExports";
import ModuleSystem from "./codes/modulesystem/ModuleSystem";

const notesModuleSystem = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesModule.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 9 key features</h4>
      <div className="post-text">
        <h4>Module System</h4>
        <p>
          Java 9 introduced the Module System, also known as Project Jigsaw,
          which aimed to address the long-standing issue of the lack of a
          modular system in the Java platform. The primary goal was to improve
          the scalability, maintainability, and security of large codebases.
        </p>
        <ul>
          <li>
            Module Declaration:
            <ul>
              <li>
                A module is a collection of related packages and resources.
              </li>
              <li>A module is defined in a module-info.java file.</li>
            </ul>
            <ModuleSystemDeclaration />
          </li>
          <li>
            Requires and Exports:
            <ul>
              <li>
                <code>requires:</code> Specifies dependencies on other modules.
              </li>
              <li>
                <code>exports:</code> Makes packages available for other modules
                to use.
              </li>
            </ul>
            <ModuleSystemRequiresExports />
          </li>
          <li>
            Modular JARs:
            <ul>
              <li>Modules are packaged into modular JAR files.</li>
              <li>The JAR file contains a module-info.class file.</li>
            </ul>
          </li>
          <li>
            Encapsulation:
            <ul>
              <li>
                Modules enforce encapsulation, allowing only explicitly exported
                packages to be accessible.
              </li>
            </ul>
          </li>
          <br />
          <p>
            <b>Benefits of Java 9 Module System:</b>
          </p>
          <li>
            Strong Encapsulation:
            <ul>
              <li>
                Modules enforce encapsulation, reducing the risk of unintended
                dependencies and enhancing security.
              </li>
            </ul>
          </li>
          <li>
            Improved Maintainability:
            <ul>
              <li>
                Modules provide a clear structure, making it easier to
                understand and maintain code.
              </li>
            </ul>
          </li>
          <li>
            Scalability:
            <ul>
              <li>
                Enables the development of large-scale applications by managing
                dependencies more efficiently.
              </li>
            </ul>
          </li>
          <li>
            Dependency Management:
            <ul>
              <li>
                Explicit module dependencies are declared, reducing classpath
                issues and making it easier to manage dependencies.
              </li>
            </ul>
          </li>
          <li>
            Readability and Maintainability:
            <ul>
              <li>
                Explicit module dependencies improve the readability of the
                codebase and facilitate maintenance.
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Example: com.example.module1 requires com.example.module2, and both
          modules export specific packages. This ensures that only the intended
          parts of each module are accessible.
        </p>
        <ModuleSystem />
      </div>
    </div>
  );
};
export default notesModuleSystem;
