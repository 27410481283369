import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIFunctionalInterfaces = () => {
  const javaCode = `
  List<String> names = Arrays.asList("Alice", "Bob", "Charlie");

  // Predicate Functional Interface
  List<String> result = names.stream()
                            .filter(name -> name.startsWith("A"))
                            .collect(Collectors.toList());
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIFunctionalInterfaces;
