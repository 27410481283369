import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RedisQueryObjects = () => {
  const javaCode = `{
    "id": "12345",
    "name": "John Doe",
    "age": 30,
    "email": "johndoe@example.com",
    "address": {
      "street": "123 Main St",
      "city": "Anytown",
      "zipcode": "12345"
    }
  },
  {
    "id": "12346",
    "name": "Jane Smith",
    "age": 29,
    "email": "janesmith@example.com",
    "address": {
      "street": "456 Elm St",
      "city": "Othertown",
      "zipcode": "54321"
    }
  }`;

  return (
    <div>
      <SyntaxHighlighter
        language="json"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RedisQueryObjects;
