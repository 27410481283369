import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const JavaCodeGraphQL = () => {
  const javaCode = `
  import graphql.schema.DataFetcher;
  import graphql.schema.GraphQLSchema;
  import graphql.schema.StaticDataFetcher;
  import graphql.schema.idl.RuntimeWiring;
  import graphql.schema.idl.SchemaGenerator;
  import graphql.schema.idl.SchemaParser;
  import graphql.schema.idl.TypeDefinitionRegistry;
  
  @Service
  public class GraphQLService {
      private GraphQL graphQL;
  
      @Autowired
      public GraphQLService(GraphQL graphQL) {
          this.graphQL = graphQL;
      }
  
      public ExecutionResult execute(String query) {
          return graphQL.execute(query);
      }
  }  
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default JavaCodeGraphQL;
