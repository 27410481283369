import React from "react";
import MethodReferenceInstance from "./codes/methodreference/MethodReferenceInstance";
import MethodReferenceStatic from "./codes/methodreference/MethodReferenceStatic";
import MethodReferenceArbitrary from "./codes/methodreference/MethodReferenceArbitrary";
import MethodReferenceConstructor from "./codes/methodreference/MethodReferenceConstructor";
import MethodReferenceExample from "./codes/methodreference/MethodReferenceExample";

const notesMethodReferences = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesMethodReference.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Method References</h4>
        <p>
          Method references are a shorthand notation of a lambda expression to
          call a method. They are a way to simplify lambda expressions when the
          lambda body is just calling a method.
        </p>
        <ul>
          <li>
            <h5>1. Conciseness and Readability:</h5>
            <b>Benefit:</b> Reduces boilerplate code and makes the code more
            concise.
            <br />
            <b>Advantage:</b> Especially useful when the lambda expression's
            body consists of a single method call.
          </li>

          <li>
            <h5>2. Improved Code Structure:</h5>
            <b>Benefit:</b> Enhances code readability by expressing the method
            call more directly.
            <br />
            <b>Advantage:</b> Makes the code structure cleaner and easier to
            understand.
          </li>

          <li>
            <h5>3. Code Reusability:</h5>
            <b>Benefit:</b> Encourages code reuse by referencing existing
            methods.
            <br />
            <b>Advantage:</b> Methods can be reused in various contexts,
            improving maintainability.
          </li>
        </ul>
        <p>Types of Method References:</p>
        <ul>
          <li>
            Reference to a Static Method Syntax:
            <br />
            <b>ClassName::staticMethodName</b>
            <MethodReferenceStatic />
          </li>
          <li>
            Reference to an Instance Method of a Particular Object Syntax:
            <br />
            <b>instance::instanceMethodName</b>
            <MethodReferenceInstance />
          </li>
          <li>
            Reference to an Instance Method of an Arbitrary Object of a
            Particular Type Syntax:
            <br />
            <b>ClassName::instanceMethodName</b>
            <MethodReferenceArbitrary />
          </li>
          <li>
            Reference to a Constructor Syntax:
            <br />
            <b>ClassName::new</b>
            <MethodReferenceConstructor />
          </li>
        </ul>
        <p>Examples:</p>
        <MethodReferenceExample />
      </div>
    </div>
  );
};
export default notesMethodReferences;
