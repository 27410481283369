import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIOperations = () => {
  const javaCode = `
    List<String> names = Arrays.asList("Alice", "Bob", "Charlie");

    // Intermediate Operation
    Stream<String> filteredStream = names.stream().filter(name -> name.length() > 3);
    
    // Terminal Operation
    List<String> result = filteredStream.collect(Collectors.toList());
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIOperations;
