import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const EnableMBeanExportExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.EnableMBeanExport;
    import org.springframework.jmx.support.RegistrationPolicy;

/** In this example, we use the @EnableMBeanExport annotation to 
 * enable MBean export for Spring beans. We specify the default 
 * JMX domain as "myDomain" and use RegistrationPolicy.REPLACE_EXISTING 
 * to replace existing MBeans with the same name. */

    @Configuration
    @EnableMBeanExport(defaultDomain = "myDomain", registration = RegistrationPolicy.REPLACE_EXISTING)
    public class MyConfig {
    
        // Configuration logic here
        
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default EnableMBeanExportExample;
