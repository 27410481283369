import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homegrey from "../src/pages/homeGrey";
import HomeHello from "../src/pages/HomeHello";
import CV from "../src/pages/CV";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homegrey />} />
          <Route path="/hello" element={<HomeHello />} />
          <Route path="/cv" element={<CV />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
