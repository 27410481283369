import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ProfileExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Profile;
    
/** In this example, we use the @Profile annotation to specify 
 * that the developmentBean() method is active when the "development" 
 * profile is active, and the productionBean() method is active when 
 * the "production" profile is active. This allows you to conditionally 
 * create beans based on the active Spring profiles. */

    @Configuration
    public class MyConfig {
    
        @Bean
        @Profile("development")
        public MyBean developmentBean() {
            return new MyBean("Development Bean");
        }

        @Bean
        @Profile("production")
        public MyBean productionBean() {
            return new MyBean("Production Bean");
        }
    }
    
    public class MyBean {
        private String message;
        
        public MyBean(String message) {
            this.message = message;
        }

        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ProfileExample;
