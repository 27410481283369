import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ReactJSConfiguration = () => {
  const reactCode = `
  {
    "compilerOptions": {
      "baseUrl": "src",
      "paths": {
        "components/*": ["components/*"],
        "utils/*": ["utils/*"]
      }
    },
    "include": ["src"]
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="json"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ReactJSConfiguration;
