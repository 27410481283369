import React from "react";
import PathAliases from "./codes/pathaliases/PathAliases";
import CustomModulePath from "./codes/pathaliases/CustomModulePath";
import UsingPathAliases from "./codes/pathaliases/UsingPathAliases";
import WebpackConfiguration from "./codes/pathaliases/WebpackConfiguration";
import WebpackUsingPathAliases from "./codes/pathaliases/WebpackUsingPathAliases";
import ReactJSConfiguration from "./codes/pathaliases/ReactJSConfiguration";
import ReactJSUsingPathAliases from "./codes/pathaliases/ReactJSUsingPathAliases";

const notesPathAliases = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesAliases.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">October 20, 2023</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Path Aliases or Path Mapping</h4>
      <div className="post-text">
        <h4>Path Aliases in Node.js</h4>
        <ul>
          <li>
            <b>Project Structure:</b> Suppose you have a Node.js project
            structured like this:
          </li>
          <PathAliases />
          <li>
            <b>Node.js Module Resolution:</b> By default, Node.js resolves
            modules relative to the current file or from the node_modules
            directory.
          </li>
          <li>
            <b>Custom Module Paths:</b> You can set up path aliases in Node.js
            by using the built-in <b>module.paths</b> property and the{" "}
            <b>require.resolve</b>
            method to create custom module paths. In your <b>index.js</b> or the
            entry point of your application, define path aliases like this:
          </li>
          <CustomModulePath />
          <li>
            <b>Using Path Aliases:</b> With these path aliases set up, you can
            use them throughout your code:
          </li>
          <UsingPathAliases />
        </ul>
        <h4>Path Aliases in Webpack</h4>
        <ul>
          <li>
            <b>Webpack Configuration:</b> To set up path aliases in Webpack,
            modify your Webpack configuration. You can create or modify a
            <b>webpack.config.js</b> file in your project root.
          </li>
          <WebpackConfiguration />
          <li>
            <b>Using Path Aliases in JavaScript/React:</b> Once you've set up
            path aliases in your Webpack configuration, you can use them in your
            JavaScript or React code:
          </li>
          <WebpackUsingPathAliases />
        </ul>
        <h4>Path Aliases in React.js</h4>
        <ul>
          <li>
            <b>React.js Configuration:</b> If you're using Create React App,
            path aliases can be set up by modifying the <b>jsconfig.json</b>{" "}
            (for JavaScript) or <b>tsconfig.json</b> (for TypeScript) file. For
            JavaScript (jsconfig.json), you can add path mappings like this:
          </li>
          <ReactJSConfiguration />
          <li>
            <b>Using Path Aliases in React.js:</b> With path aliases set up, you
            can use them in your React components:
          </li>
          <ReactJSUsingPathAliases />
        </ul>
      </div>
    </div>
  );
};
export default notesPathAliases;
