import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIAbsence = () => {
  const javaCode = `
  List<String> names = Arrays.asList("Alice", "Bob", "Charlie");

  // Optional for Absence of Values
  Optional<String> firstMatch = names.stream()
                                    .filter(name -> name.startsWith("Z"))
                                    .findFirst();
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIAbsence;
