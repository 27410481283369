import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const PathAliases = () => {
  const reactCode = `
  my-node-project/
  ├── src/
  │   ├── components/
  │   │   ├── myComponent.js
  │   ├── utils/
  │   │   ├── utilFunction.js
  └── index.js
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="css"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default PathAliases;
