import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const RemoveNativeHeaderTool = () => {
  const javaCode = `
    public class RemoveNativeHeaderToolExample {

        // Load the native library
        static {
            System.loadLibrary("RemoveNativeHeaderToolExample");
        }

        // Native method declaration
        public native void nativeMethod();

        // Java method calling the native method
        public void callNativeMethod() {
            System.out.println("Calling native method...");
            nativeMethod();
        }

        public static void main(String[] args) {
            System.out.println("Java 10: Removal of Native-Header Generation Tool");

            // Create an instance of the class
            RemoveNativeHeaderToolExample example = new RemoveNativeHeaderToolExample();

            // Call the Java method, which internally calls the native method
            example.callNativeMethod();
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default RemoveNativeHeaderTool;
