import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const EnableAspectJAutoProxyExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.AnnotationConfigApplicationContext;
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.EnableAspectJAutoProxy;
    

    /** In this example, we use the @EnableAspectJAutoProxy annotation 
     * to enable support for AspectJ-style (annotation-driven) aspect-oriented 
     * programming in a Spring configuration class (MyConfig). 
     * We also define a custom aspect (MyAspect) and a service class (MyService) 
     * to demonstrate aspect-oriented programming. */
    @Configuration
    @EnableAspectJAutoProxy
    public class MyConfig {
    
        @Bean
        public MyAspect myAspect() {
            return new MyAspect();
        }
    
        @Bean
        public MyService myService() {
            return new MyService();
        }
    }
    
    public class MyAspect {
        // Aspect logic here
    }
    
    public class MyService {
        // Service logic here
    }
    
    public class MainApp {
        public static void main(String[] args) {
            AnnotationConfigApplicationContext context = new AnnotationConfigApplicationContext(MyConfig.class);
            MyService service = context.getBean(MyService.class);
            service.doSomething();
            context.close();
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default EnableAspectJAutoProxyExample;
