import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const CompletableFuture = () => {
  const javaCode = `
  import java.util.concurrent.CompletableFuture;
  import java.util.concurrent.ExecutionException;

  public class CompletableFutureExample {

      public static void main(String[] args) {
          // Example 1: Creating a CompletableFuture
          CompletableFuture<String> completableFuture = CompletableFuture.supplyAsync(() -> "Hello");

          // Example 2: Applying Transformation
          CompletableFuture<String> transformedFuture = completableFuture.thenApply(s -> s + ", CompletableFuture!");

          // Example 3: Combining Multiple CompletableFutures
          CompletableFuture<String> future1 = CompletableFuture.supplyAsync(() -> "Hello");
          CompletableFuture<String> future2 = CompletableFuture.supplyAsync(() -> "CompletableFuture");
          CompletableFuture<String> combinedFuture = future1.thenCombine(future2, (s1, s2) -> s1 + " " + s2);

          // Example 4: Exception Handling
          CompletableFuture<String> exceptionallyFuture = completableFuture.thenApply(s -> {
              throw new RuntimeException("Exception in processing!");
          }).exceptionally(ex -> "Handled Exception: " + ex.getMessage());

          try {
              // Example 5: Blocking and Getting Result
              String result = completableFuture.get();
              System.out.println("Result: " + result);

              // Example 6: Composing Asynchronous Operations
              String composedResult = completableFuture
                      .thenApply(s -> s + ", CompletableFuture!")
                      .thenCompose(s -> CompletableFuture.supplyAsync(() -> s + " Composed!"))
                      .get();
              System.out.println("Composed Result: " + composedResult);

          } catch (InterruptedException | ExecutionException e) {
              e.printStackTrace();
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default CompletableFuture;
