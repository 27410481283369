import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIAggregation = () => {
  const javaCode = `
  List<String> names = Arrays.asList("Alice", "Bob", "Charlie");

  // Collectors for Aggregation
  String result = names.stream()
                     .collect(Collectors.joining(", "));
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIAggregation;
