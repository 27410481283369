import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "./settingsnew";
import { createGlobalStyle } from "styled-components";
import AOS from "aos";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import NotesCommunication from "./notes/notesCommunication";
import NotesGateway from "./notes/notesGateway";
import NotesAnnotations from "./notes/notesAnnotations";
import NotesJava8 from "./notes/notesJava8";
import NotesLambdaExpression from "./notes/notesLambdaExpression";
import NotesPathAliases from "./notes/notesPathAliases";
import NotesStreamAPI from "./notes/notesStreamAPI";
import NotesDefaultMethods from "./notes/notesDefaultMethods";
import NotesMethodReferences from "./notes/notesMethodReferences";
import NotesOptionalClass from "./notes/notesOptionalClass";
import NotesDateAndTimeAPI from "./notes/notesDateAndTimeAPI";
import NotesNashornJS from "./notes/notesNashornJS";
import NotesCompletableFuture from "./notes/notesCompletableFuture";
import NotesRedis from "./notes/notesRedis";
import NotesRepeatable from "./notes/notesRepeatable";
import NotesJava9 from "./notes/notesJava9";
import NotesModuleSystem from "./notes/notesModuleSystem";
import NotesJShell from "./notes/notesJShell";
import NotesHTTPClient from "./notes/notesHTTPClient";
import NotesIPAPI from "./notes/notesIPAPI";
import NotesPrivateMethods from "./notes/notesPrivateMethods";
import NotesImprovedStreamAPI from "./notes/notesImprovedStreamAPI";
import NotesTryWithResources from "./notes/notesTryWithResources";
import NotesDiamondOperator from "./notes/notesDiamondOperator";
import NotesMultiReleaseJARs from "./notes/notesMultiReleaseJARs";
import NotesReactiveStreams from "./notes/notesReactiveStreams";
import NotesJava10 from "./notes/notesJava10";
import NotesJava10Var from "./notes/notesJava10Var";
import NotesJava10GCI from "./notes/notesGarbageCollectorInterface";
import NotesACDS from "./notes/NotesApplicationClassDataSharing";
import NotesParallelFullGCForG1 from "./notes/notesParallelFullGCForG1";
import NotesTLH from "./notes/notesThreadLocalHandshakes";
import NotesRNHT from "./notes/notesRemoveNativeHeaderTool";
import NotesAULTE from "./notes/notesAdditionalUnicodeLanguageTagExtensions";
import NotesGraalJIT from "./notes/notesGraalJITCompiler";
import NotesJava11 from "./notes/notesJava11";
import NotesJava11LVSLP from "./notes/notesLocalVariableSyntax";
import NotesJava11HttpSC from "./notes/notesHttpStandardClientBenefits";
import NotesJava11NBAC from "./notes/notesNestBasedAccessControl";
import NotesJava11DCFC from "./notes/notesDynamicClassFileConstants";
import NotesRedisGeoArticle from "./notes/notesRedisGeoArticle";
import NotesRedisQueryArticle from "./notes/notesRedisQueryArticle";
AOS.init();

const GlobalStyles = createGlobalStyle`
    body.hide{
          overflow-y: hidden; /* Hide vertical scrollbar */
          overflow-x: hidden; /* Hide horizontal scrollbar */
        }
`;

class CustomSlide extends Component {
  render() {
    const { index, ...props } = this.props;
    return <div {...props}></div>;
  }
}

const Notes = function () {
  /* lightbox1 */
  const [lighbx, setlighbx] = React.useState(false);
  const handleBtnClick = () => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "hidden";
  };
  const [selectedSlideIndex, setSelectedSlideIndex] = React.useState(0);
  const handleBtnClickclose = () => {
    setlighbx(!lighbx);
    var x = document.getElementsByTagName("BODY")[0];
    x.style.overflow = "auto";
    setSelectedSlideIndex(0);
  };

  const handleSlideClick = (index) => {
    setSelectedSlideIndex(index);
    handleBtnClick(); // Open the lightbox
  };

  return (
    <div className="container">
      <GlobalStyles />
      <div className="spacer-single"></div>
      <div className="row">
        <div className="col-md-12 text-center">
          <h2>Notes</h2>
          <div className="space-border"></div>
        </div>
      </div>
      <div className="row" data-aos="fade-up" data-aos-once="true">
        <Slider {...settings}>
          <CustomSlide
            className="itm"
            index={1}
            onClick={() => handleSlideClick(1)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesCommunication.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java" color="primary" variant="outlined" />
                      <Chip
                        label="Rest API"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="graphQL"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="WebSocket"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    How to connect FE to BE services ...
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={2}
            onClick={() => handleSlideClick(2)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesGateway.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="API Gateway"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="Zuul" color="primary" variant="outlined" />
                      <Chip label="KongHQ" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Gateway solutions
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={3}
            onClick={() => handleSlideClick(3)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesAnnotations.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java" color="primary" variant="outlined" />
                      <Chip
                        label="Annotations"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="Spring" color="primary" variant="outlined" />
                      <Chip label="Lombok" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Spring and Lombok Annotations
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={4}
            onClick={() => handleSlideClick(4)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notes8.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Java 8 key features
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={5}
            onClick={() => handleSlideClick(5)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesLambda.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Lambda Expression"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Java 8 Lambda Expression
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={6}
            onClick={() => handleSlideClick(6)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesAliases.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Path Aliases"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Node.js"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Webpack"
                        color="primary"
                        variant="outlined"
                      />{" "}
                      <Chip
                        label="React.js"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Path Aliases
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={7}
            onClick={() => handleSlideClick(7)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesStreamAPI.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Stream API"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Stream API
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={8}
            onClick={() => handleSlideClick(8)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesDefaultMethod.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Default Methods"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Default Methods
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={9}
            onClick={() => handleSlideClick(9)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesMethodReference.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Method References"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Method References
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={10}
            onClick={() => handleSlideClick(10)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesOptional.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Optional Class"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Optional Class
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={11}
            onClick={() => handleSlideClick(11)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesDateAndTime.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Date and Time API"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Date and Time API
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={12}
            onClick={() => handleSlideClick(12)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesNashorn.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Nashorn JS Engine"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Nashorn JS Engine
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={13}
            onClick={() => handleSlideClick(13)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesCompletableFuture.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Completable Future"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Completable Future
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={14}
            onClick={() => handleSlideClick(14)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesRedis.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Redis" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Redis
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={15}
            onClick={() => handleSlideClick(15)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesReuse.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 8" color="primary" variant="outlined" />
                      <Chip
                        label="Repeatable Annotations"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Repeatable Annotations
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={16}
            onClick={() => handleSlideClick(16)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesJava9.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Java 9
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={17}
            onClick={() => handleSlideClick(17)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesModule.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip label="Module" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Module System (Project Jigsaw)
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={18}
            onClick={() => handleSlideClick(18)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesJShell.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip label="JShell" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    JShell
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={19}
            onClick={() => handleSlideClick(19)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesClient.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="HTTP/2 Client"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    HTTP/2 Client
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={20}
            onClick={() => handleSlideClick(20)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesIPAPI.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="Process API"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Improved Process API
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={21}
            onClick={() => handleSlideClick(21)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesPrivate.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="Private Methods"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Private Methods
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={22}
            onClick={() => handleSlideClick(22)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesImprovedStreamAPI.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="Improved Stream API"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Improved Stream API
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={23}
            onClick={() => handleSlideClick(23)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesTry.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="TryWithResources"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    TryWithResources
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={24}
            onClick={() => handleSlideClick(24)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesDiamondOperator.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="Diamond Operator"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Diamond Operator
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={25}
            onClick={() => handleSlideClick(25)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesMultithreadJars.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="MultiRelease JARs"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    MultiRelease JARs
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={26}
            onClick={() => handleSlideClick(26)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesReactiveStreams.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Java 9" color="primary" variant="outlined" />
                      <Chip
                        label="Reactive"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Streams API"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Reactive Streams API
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={27}
            onClick={() => handleSlideClick(27)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesJava10.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Java 10
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={28}
            onClick={() => handleSlideClick(28)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesJava10Var.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="var" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    var
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={29}
            onClick={() => handleSlideClick(29)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesGarbageCollectorInterface.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="GC" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Garbage Collector Interface
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={30}
            onClick={() => handleSlideClick(30)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesACDS.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Data Sharing"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Application Class Data Sharing
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={31}
            onClick={() => handleSlideClick(31)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesParallelFullGCForG1.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="G1" color="primary" variant="outlined" />
                      <Chip
                        label="Full GC"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Parallel Full GC for G1
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={32}
            onClick={() => handleSlideClick(32)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesThreadLocalHandshakes.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="Thread" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Thread-Local Handshakes
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={33}
            onClick={() => handleSlideClick(33)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesRNHT.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Remove Native-Header"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Remove Native-Header
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={34}
            onClick={() => handleSlideClick(34)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesAULTE.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Unicode"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Language"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="Tag" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Unicode Language-Tag extensions
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={35}
            onClick={() => handleSlideClick(35)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesGraal.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 10"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="Graal" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Java-Based JIT Compiler (Graal)
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={36}
            onClick={() => handleSlideClick(36)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesJava11.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 11"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Java 11
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={37}
            onClick={() => handleSlideClick(37)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesLVSLP.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 11"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="var" color="primary" variant="outlined" />
                      <Chip label="Lambda" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Variable for Lambda Parameters
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={38}
            onClick={() => handleSlideClick(38)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesHttpStandardClientBenefits.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 11"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="HTTP Client"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    HTTP Client (Standard)
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={39}
            onClick={() => handleSlideClick(39)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesNestBasedAccessControl.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 11"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip label="Nest" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Nest-Based Access Control
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>

          <CustomSlide
            className="itm"
            index={40}
            onClick={() => handleSlideClick(40)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesDynamicClassFileConstants.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label="Java 11"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Dynamic "
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Class-File"
                        color="primary"
                        variant="outlined"
                      />
                      <Chip
                        label="Constants"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Dynamic Class-File Constants
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>
          <CustomSlide
            className="itm"
            index={41}
            onClick={() => handleSlideClick(41)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesRedisGeospatial.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Redis" color="primary" variant="outlined" />
                      <Chip
                        label="Geospatial"
                        color="primary"
                        variant="outlined"
                      />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Redis Geospatial
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>
          <CustomSlide
            className="itm"
            index={42}
            onClick={() => handleSlideClick(42)}
          >
            <div className="bloglist item">
              <div className="post-content">
                <div className="post-image">
                  <div className="de_modal" onClick={handleBtnClick}>
                    <img
                      alt="blogimg"
                      src="./img/notes/notesRedisQuery.jpg"
                      className="grayscale"
                    />
                  </div>
                </div>
                <div className="post-text">
                  <Stack spacing={1} alignItems="center">
                    <Stack direction="row" spacing={1}>
                      <Chip label="Redis" color="primary" variant="outlined" />
                      <Chip label="Query" color="primary" variant="outlined" />
                    </Stack>
                  </Stack>
                  <h4 className="de_modal" onClick={handleBtnClick}>
                    Redis Query
                  </h4>
                </div>
              </div>
            </div>
          </CustomSlide>
        </Slider>
      </div>

      {/* lightbox1 */}
      {lighbx && selectedSlideIndex !== null && (
        <div className="LightboxGal">
          <div className="closeGal">
            <button
              className="button-close"
              onClick={handleBtnClickclose}
            ></button>
          </div>
          <div className="v-center w-100">
            <div className="mainLightbox container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  <div className="blog-read">
                    {selectedSlideIndex === 1 && <NotesCommunication />}
                    {selectedSlideIndex === 2 && <NotesGateway />}
                    {selectedSlideIndex === 3 && <NotesAnnotations />}
                    {selectedSlideIndex === 4 && <NotesJava8 />}
                    {selectedSlideIndex === 5 && <NotesLambdaExpression />}
                    {selectedSlideIndex === 6 && <NotesPathAliases />}
                    {selectedSlideIndex === 7 && <NotesStreamAPI />}
                    {selectedSlideIndex === 8 && <NotesDefaultMethods />}
                    {selectedSlideIndex === 9 && <NotesMethodReferences />}
                    {selectedSlideIndex === 10 && <NotesOptionalClass />}
                    {selectedSlideIndex === 11 && <NotesDateAndTimeAPI />}
                    {selectedSlideIndex === 12 && <NotesNashornJS />}
                    {selectedSlideIndex === 13 && <NotesCompletableFuture />}
                    {selectedSlideIndex === 14 && <NotesRedis />}
                    {selectedSlideIndex === 15 && <NotesRepeatable />}
                    {selectedSlideIndex === 16 && <NotesJava9 />}
                    {selectedSlideIndex === 17 && <NotesModuleSystem />}
                    {selectedSlideIndex === 18 && <NotesJShell />}
                    {selectedSlideIndex === 19 && <NotesHTTPClient />}
                    {selectedSlideIndex === 20 && <NotesIPAPI />}
                    {selectedSlideIndex === 21 && <NotesPrivateMethods />}
                    {selectedSlideIndex === 22 && <NotesImprovedStreamAPI />}
                    {selectedSlideIndex === 23 && <NotesTryWithResources />}
                    {selectedSlideIndex === 24 && <NotesDiamondOperator />}
                    {selectedSlideIndex === 25 && <NotesMultiReleaseJARs />}
                    {selectedSlideIndex === 26 && <NotesReactiveStreams />}
                    {selectedSlideIndex === 27 && <NotesJava10 />}
                    {selectedSlideIndex === 28 && <NotesJava10Var />}
                    {selectedSlideIndex === 29 && <NotesJava10GCI />}
                    {selectedSlideIndex === 30 && <NotesACDS />}
                    {selectedSlideIndex === 31 && <NotesParallelFullGCForG1 />}
                    {selectedSlideIndex === 32 && <NotesTLH />}
                    {selectedSlideIndex === 33 && <NotesRNHT />}
                    {selectedSlideIndex === 34 && <NotesAULTE />}
                    {selectedSlideIndex === 35 && <NotesGraalJIT />}
                    {selectedSlideIndex === 36 && <NotesJava11 />}
                    {selectedSlideIndex === 37 && <NotesJava11LVSLP />}
                    {selectedSlideIndex === 38 && <NotesJava11HttpSC />}
                    {selectedSlideIndex === 39 && <NotesJava11NBAC />}
                    {selectedSlideIndex === 40 && <NotesJava11DCFC />}
                    {selectedSlideIndex === 41 && <NotesRedisGeoArticle />}
                    {selectedSlideIndex === 42 && <NotesRedisQueryArticle />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notes;
