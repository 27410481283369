import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const TryWithResources = () => {
  const javaCode = `
  import java.io.BufferedReader;
  import java.io.IOException;
  import java.io.StringReader;

  public class TryWithResourcesExample {

      public static void main(String[] args) {
          String inputString = "Hello, this is a sample string.";

          // Java 8: Using try-with-resources with explicit final variable
          try (BufferedReader reader = new BufferedReader(new StringReader(inputString))) {
              String line = reader.readLine();
              System.out.println("Java 8: " + line);
          } catch (IOException e) {
              e.printStackTrace();
          }

          // Java 9: Using try-with-resources with effectively final variable
          BufferedReader newReader = new BufferedReader(new StringReader(inputString));
          try (newReader) {
              String line = newReader.readLine();
              System.out.println("Java 9: " + line);
          } catch (IOException e) {
              e.printStackTrace();
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default TryWithResources;
