import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const MultiReleaseJARs = () => {
  const javaCode = `
  // Main class with version-specific implementations
  public class MultiReleaseJARsExample {

      // Common functionality available in all versions
      public void commonFunction() {
          System.out.println("Common functionality available in all versions.");
      }

      // Java 8 version-specific implementation
      public void java8SpecificFunction() {
          System.out.println("Java 8 specific functionality.");
      }

      // Java 9 version-specific implementation
      public void java9SpecificFunction() {
          System.out.println("Java 9 specific functionality.");
      }

      // Entry point for demonstration
      public static void main(String[] args) {
          MultiReleaseJARsExample example = new MultiReleaseJARsExample();

          // Common functionality
          example.commonFunction();

          // Version-specific functionality based on the Java runtime version
          Runtime.Version version = Runtime.version();
          int major = version.major();

          if (major >= 9) {
              example.java9SpecificFunction();
          } else {
              example.java8SpecificFunction();
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default MultiReleaseJARs;
