import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
const AliasForExample = () => {
  const javaCode = `
  /** In this example, we define a custom annotation 
   * @MyAnnotation that uses the @AliasFor annotation 
   * to specify that the name attribute is an alias for 
   * the value attribute. This means that you can use 
   * either name or value when annotating a method with 
   * @MyAnnotation, and it will have the same effect. 
   * The usage of @MyAnnotation in the MyClass class 
   * demonstrates how to use this custom annotation 
   * in your code. */
      @Target(ElementType.METHOD)
      @Retention(RetentionPolicy.RUNTIME)
      @interface MyAnnotation {
        @AliasFor("value")
        String name() default "";
        
        @AliasFor("name")
        String value() default "";
      }
      
      // Usage of MyAnnotation
      public class MyClass {
        @MyAnnotation(name = "example")
        public void myMethod() {
          // Method logic here
        }
      }
    `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default AliasForExample;
