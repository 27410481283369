import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8Collectors = () => {
  const javaCode = `
  // New Collectors: The Collectors class introduced several new static factory methods to facilitate the creation of collectors for use with streams.
  // Using Collectors to join a list of strings
  List<String> names = Arrays.asList("Alice", "Bob", "Charlie");
  String joinedNames = names.stream()
                           .collect(Collectors.joining(", "));
  System.out.println("Joined Names: " + joinedNames);
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8Collectors;
