import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { CardMedia, Typography } from "@mui/material";
import { useState } from "react";
import { auto } from "@popperjs/core";

const defaultTheme = createTheme();

export default function HomeHello() {
  const [raiseCV, setRaisedCV] = useState(false);
  const [raiseLI, setRaisedLI] = useState(false);
  const [raiseIO, setRaisedIO] = useState(false);
  const [raiseGH, setRaisedGH] = useState(false);
  const toggleRaisedCV = () => setRaisedCV((prev) => !prev);
  const toggleRaisedLI = () => setRaisedLI((prev) => !prev);
  const toggleRaisedIO = () => setRaisedIO((prev) => !prev);
  const toggleRaisedGH = () => setRaisedGH((prev) => !prev);

  const tiers = [
    {
      title: "CV",
      image: "img/hello/cv.png",
      color: "#00c853",
      state: raiseCV,
      setter: toggleRaisedCV,
      link: "https://ouuu.io/cv",
    },
    {
      title: "LinkedIn",
      image: "img/hello/linkedin.png",
      color: "#0277bd",
      state: raiseLI,
      setter: toggleRaisedLI,
      link: "https://linkedin.com/in/tosunufuk",
    },
    {
      title: "ouuu.io",
      image: "img/hello/ouuu.png",
      color: "#ffc107",
      state: raiseIO,
      setter: toggleRaisedIO,
      link: "https://ouuu.io",
    },
    {
      title: "GitHub",
      image: "img/hello/github.png",
      color: "#171717",
      state: raiseGH,
      setter: toggleRaisedGH,
      link: "https://github.com/tosunufuk",
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <Container maxWidth="md" component="main">
        <Typography
          variant="h2"
          component="h2"
          color={"black"}
          sx={{
            marginTop: {
              xs: 0, // No margin on extra small screens
              md: "12rem", // Margin of 20px on large screens and above
              textAlign: "center",
            },
          }}
        >
          Hi, I am Ufuk Tosun.
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="flex-end"
          justifyContent="center"
        >
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={6}
              sm={4}
              md={3}
              justifyContent="center"
              alignItems="center"
              sx={{
                marginTop: {
                  xs: 0, // No margin on extra small screens
                  md: "5rem", // Margin of 5rem on large screens and above
                },
                marginLeft: {
                  xs: "5rem",
                  md: auto,
                },
                marginRight: {
                  xs: "5rem",
                  md: auto,
                },
              }}
            >
              <a
                href={tier.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Card
                  onMouseOver={tier.setter}
                  onMouseOut={tier.setter}
                  raised={tier.state}
                >
                  <CardHeader
                    title={tier.title}
                    titleTypographyProps={{ align: "center" }}
                    sx={{
                      backgroundColor: `${tier.color}`,
                      color: "#e3f2fd",
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    ></Box>
                    <CardMedia component="img" image={tier.image} />
                  </CardContent>
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
