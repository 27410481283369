import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8FunctionalInterfaceConsumer = () => {
  const javaCode = `
  Consumer<String> printUpperCase = str -> System.out.println(str.toUpperCase());
  printUpperCase.accept("hello"); // Output: "HELLO"
    `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8FunctionalInterfaceConsumer;
