import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const EnableLoadTimeWeavingExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.EnableLoadTimeWeaving;
    import org.springframework.instrument.classloading.InstrumentationLoadTimeWeaver;

/** In this example, we use the @EnableLoadTimeWeaving annotation to enable load-time 
 * weaving for Spring applications, specifically using AspectJ weaving 
 * (AspectJWeaving.ENABLED). This annotation allows you to apply aspects at runtime 
 * to classes loaded by the application's classloader. */

    @Configuration
    @EnableLoadTimeWeaving(aspectjWeaving = EnableLoadTimeWeaving.AspectJWeaving.ENABLED)
    public class MyConfig {
    
        // Configuration logic here
        
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default EnableLoadTimeWeavingExample;
