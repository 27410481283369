import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8FunctionalInterfaceSupplier = () => {
  const javaCode = `
  Supplier<Double> randomDouble = () -> Math.random();
  double value = randomDouble.get(); // Result: (random double value)
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8FunctionalInterfaceSupplier;
