import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const DiamondOperator = () => {
  const javaCode = `
  import java.util.ArrayList;
  import java.util.List;

  public class DiamondOperatorExample {

      public static void main(String[] args) {
          // Java 8: Verbose code without diamond operator
          List<String> list1 = new ArrayList<String>() {
              {
                  add("Java");
                  add("9");
                  add("Diamond");
              }
          };

          // Java 9: Concise code with diamond operator
          List<String> list2 = new ArrayList<>() {
              {
                  add("Java");
                  add("9");
                  add("Diamond");
              }
          };

          // Displaying the contents of both lists
          System.out.println("List 1 (Java 8): " + list1);
          System.out.println("List 2 (Java 9): " + list2);
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default DiamondOperator;
