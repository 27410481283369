import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ImprovedStreamAPI = () => {
  const javaCode = `
  import java.util.List;
  import java.util.stream.Collectors;
  import java.util.stream.Stream;
  
  public class ImprovedStreamExample {
  
      public static void main(String[] args) {
          // Example 1: takeWhile
          List<Integer> numbers = Stream.of(1, 2, 3, 4, 5, 2, 6)
                  .takeWhile(n -> n < 4)
                  .collect(Collectors.toList());
          System.out.println("takeWhile example: " + numbers);
  
          // Example 2: dropWhile
          List<Integer> remainingNumbers = Stream.of(1, 2, 3, 4, 5, 2, 6)
                  .dropWhile(n -> n < 4)
                  .collect(Collectors.toList());
          System.out.println("dropWhile example: " + remainingNumbers);
  
          // Example 3: ofNullable
          String nonNullValue = Stream.ofNullable("Hello")
                  .findFirst()
                  .orElse("Default");
          String nullValue = Stream.ofNullable(null)
                  .findFirst()
                  .orElse("Default");
          System.out.println("ofNullable example 1: " + nonNullValue);
          System.out.println("ofNullable example 2: " + nullValue);
  
          // Example 4: Iterating over collections
          List<String> names = List.of("Alice", "Bob", "Charlie");
          names.stream().forEachOrdered(System.out::println);
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ImprovedStreamAPI;
