import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const EventListenerExample = () => {
  const javaCode = `
    import org.springframework.context.event.EventListener;
    import org.springframework.stereotype.Component;

/** In this example, we use the @EventListener annotation to 
 * define a method handleCustomEvent that listens for custom 
 * events of type CustomEvent. The method will be invoked when 
 * such events are published within the Spring application context. */

    @Component
    public class MyEventListener {

      @EventListener
      public void handleCustomEvent(CustomEvent customEvent) {
        // Event handling logic here
      }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default EventListenerExample;
