import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const AnnotatedElementUtilsExample = () => {
  const javaCode = `
      import org.springframework.core.annotation.AnnotatedElementUtils;
      import org.springframework.core.annotation.AnnotationAttributes;
      
      import java.lang.reflect.Method;
      /** In this example, we use the @AnnotatedElementUtils class 
       * from the Spring Core Container to retrieve annotation 
       * attributes from a method annotated with @MyAnnotation. 
       * The example demonstrates how to obtain annotation attributes 
       * using AnnotatedElementUtils.getMergedAnnotationAttributes().*/
      public class MyAnnotationUtilsExample {
        public static void main(String[] args) {
          Class<?> myClass = MyClass.class;
          Method myMethod = null;
          try {
            myMethod = myClass.getMethod("myMethod");
          } catch (NoSuchMethodException e) {
            e.printStackTrace();
          }
          
          AnnotationAttributes attributes = AnnotatedElementUtils.getMergedAnnotationAttributes(myMethod, MyAnnotation.class);
          
          if (attributes != null) {
            String name = attributes.getString("name");
            System.out.println("Name from MyAnnotation: " + name);
          }
        }
      }
      
      @MyAnnotation(name = "example")
      public class MyClass {
        public void myMethod() {
          // Method logic here
        }
      }
    `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default AnnotatedElementUtilsExample;
