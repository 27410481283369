import React from "react";
import DefaultMethod from "./codes/defaultmethod/DefaultMethod";

const notesDefaultMethods = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesDefaultMethod.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Default Methods</h4>
        <p>
          Default methods are methods in interfaces that have a default
          implementation. They provide a way to add new methods to an interface
          without affecting the classes that already implement it. Default
          methods are defined using the default keyword.
        </p>
        <ul>
          <li>
            <h5>1. Backward Compatibility:</h5>
            <b>Benefit:</b> Allows the addition of new methods to interfaces
            without breaking existing implementations.
            <br />
            <b>Advantage:</b> Existing classes that implement the interface
            don't need to provide an implementation for the new method unless
            they want to override the default behavior.
          </li>

          <li>
            <h5>2. Interface Evolution:</h5>
            <b>Benefit:</b> Facilitates the evolution of interfaces over time.
            <br />
            <b>Advantage:</b> Interfaces can be enhanced without requiring
            changes to all implementing classes.
          </li>

          <li>
            <h5>3. Multiple Inheritance:</h5>
            <b>Benefit:</b> Default methods address the diamond problem in
            multiple inheritance.
            <br />
            <b>Advantage:</b> If a class implements multiple interfaces with
            default methods having the same signature, the compiler provides
            rules to handle the conflict.
          </li>

          <DefaultMethod />
        </ul>
      </div>
    </div>
  );
};
export default notesDefaultMethods;
