import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8DateAndTimeAPI = () => {
  const javaCode = `
  // Date and Time API (java.time): Java 8 introduced a new Date and Time API, which is more comprehensive and flexible than the previous java.util.Date and java.util.Calendar classes.
  // Creating and formatting a date using java.time
  LocalDate today = LocalDate.now();
  DateTimeFormatter formatter = DateTimeFormatter.ofPattern("dd-MM-yyyy");
  String formattedDate = today.format(formatter);
  System.out.println("Formatted Date: " + formattedDate);
   `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8DateAndTimeAPI;
