import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java10ThreadLocalHandshake = () => {
  const javaCode = `
  import java.util.concurrent.CountDownLatch;

  public class ThreadLocalHandshakeExample {

      public static void main(String[] args) {
          // Create a CountDownLatch with a count of 1
          CountDownLatch latch = new CountDownLatch(1);

          // Create and start a worker thread
          Thread workerThread = new Thread(() -> {
              // Perform some thread-specific tasks
              performThreadSpecificTasks();

              // Notify the main thread that the tasks are done
              latch.countDown();
          });

          // Start the worker thread
          workerThread.start();

          // Wait for the worker thread to complete its tasks
          try {
              latch.await();
          } catch (InterruptedException e) {
              e.printStackTrace();
          }
      }

      private static void performThreadSpecificTasks() {
          // Use Thread-Local Handshakes to perform thread-specific actions
          System.out.println("Thread-specific tasks are performed.");
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java10ThreadLocalHandshake;
