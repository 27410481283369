import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const WebpackConfiguration = () => {
  const reactCode = `
  // File: webpack.config.js
  const path = require('path');
  
  module.exports = {
    // ...other webpack configurations
  
    resolve: {
      alias: {
        components: path.resolve(__dirname, 'src/components'),
        utils: path.resolve(__dirname, 'src/utils'),
      },
    },
  };
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="javascript"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default WebpackConfiguration;
