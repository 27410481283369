import React from "react";
import JSIntegration from "./codes/javascript/JSIntegration";

const notesNashornJS = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesNashorn.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Nashorn JavaScript Engine</h4>
        <p>
          The Nashorn JavaScript Engine, introduced in Java 8, allows developers
          to execute JavaScript code within Java applications. It provides a
          seamless integration of JavaScript and Java, enabling interoperability
          between the two languages.
        </p>
        <ul>
          <li>
            <h5>Integration with Java:</h5>
            <b>Benefit:</b> Nashorn provides a way to execute JavaScript code
            directly within Java applications.
            <br />
            <b>Advantage:</b> Enables developers to leverage the strengths of
            both Java and JavaScript in the same application.
          </li>

          <li>
            <h5>Improved Performance:</h5>
            <b>Benefit:</b> Nashorn is designed to offer better performance
            compared to the earlier Rhino JavaScript engine.
            <br />
            <b>Advantage:</b> Enhances the execution speed of JavaScript code
            within Java applications.
          </li>

          <li>
            <h5>Access to Java APIs:</h5>
            <b>Benefit:</b> Nashorn allows JavaScript code to seamlessly
            interact with Java classes and APIs.
            <br />
            <b>Advantage:</b> Enables developers to utilize existing Java
            libraries and functionalities in JavaScript code.
          </li>

          <li>
            <h5>Compatibility with ECMAScript 5.1:</h5>
            <b>Benefit:</b> Nashorn supports ECMAScript 5.1, providing
            compatibility with modern JavaScript features.
            <br />
            <b>Advantage:</b> Allows developers to use the latest JavaScript
            language features within Java applications.
          </li>

          <li>
            <h5>JVM Integration:</h5>
            <b>Benefit:</b> Nashorn runs on the Java Virtual Machine (JVM),
            providing platform independence.
            <br />
            <b>Advantage:</b> Facilitates the integration of JavaScript code
            into Java applications, making it compatible across different
            platforms.
          </li>
        </ul>
        <p>Examples:</p>
        <JSIntegration />
        <p>
          <b>Important Note</b>
        </p>
        <p>
          Nashorn was <b>deprecated</b> starting from Java 11, and it has been
          removed in Java 15. The primary reason for deprecating and removing
          Nashorn is the introduction of the <b>GraalVM JavaScript engine</b>.
          GraalVM provides better performance and is more actively developed. It
          offers improved support for running various languages, including
          JavaScript, and it is designed to be more efficient and adaptable than
          Nashorn. The decision to deprecate Nashorn and favor GraalVM aligns
          with the ongoing efforts to enhance the overall performance and
          versatility of the Java platform.
        </p>
      </div>
    </div>
  );
};
export default notesNashornJS;
