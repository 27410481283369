import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ScopeExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Scope;
    
    /** In this example, we use the @Scope annotation to define 
     * the scope of a bean. In this case, we've set the scope to 
     * "prototype", indicating that a new instance of 
     * MyPrototypeBean should be created every time it's 
     * requested from the Spring application context. */
    
    @Configuration
    public class MyConfig {
    
        @Bean
        @Scope("prototype")
        public MyPrototypeBean prototypeBean() {
            return new MyPrototypeBean();
        }
    }
    
    public class MyPrototypeBean {
        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ScopeExample;
