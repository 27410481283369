import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const DefaultMethod = () => {
  const javaCode = `
    // Original Interface
    interface MyInterface {
        void regularMethod();
    
        // Default Method
        default void defaultMethod() {
            System.out.println("Default implementation in MyInterface");
        }
    }
    
    // Class Implementing the Interface
    class MyClass implements MyInterface {
        @Override
        public void regularMethod() {
            System.out.println("Implemented regularMethod");
        }
    
        // No need to override defaultMethod unless a custom implementation is desired
    }
    
    public class Main {
        public static void main(String[] args) {
            MyClass myObject = new MyClass();
            myObject.regularMethod();      // Output: Implemented regularMethod
            myObject.defaultMethod();      // Output: Default implementation in MyInterface
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default DefaultMethod;
