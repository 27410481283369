import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const MethodReferenceArbitrary = () => {
  const javaCode = `
  List<String> words = Arrays.asList("apple", "orange", "banana");

  // Lambda Expression
  words.forEach(s -> System.out.println(s.length()));

  // Method Reference
  words.forEach(System.out::println);
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default MethodReferenceArbitrary;
