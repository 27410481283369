import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const JavaCodeDirectCommunication = () => {
  const javaCode = `
  public class UserService {
    public User getUserById(int userId) {
        // Logic to fetch user from the database
    }

    public User createUser(User user) {
        // Logic to create a new user in the database
    }
}

public class UserController {
    private UserService userService = new UserService();

    public User getUser(int userId) {
        return userService.getUserById(userId);
    }

    public User createUser(User user) {
        return userService.createUser(user);
    }
}

  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default JavaCodeDirectCommunication;
