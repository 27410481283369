import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const MethodReferenceExample = () => {
  const javaCode = `
  import java.util.Arrays;
  import java.util.List;
  import java.util.function.Consumer;
  import java.util.function.Function;
  import java.util.function.Supplier;

  public class MethodReferencesExample {
      public static void main(String[] args) {
          // Reference to a Static Method
          Function<String, Integer> parseIntReference = Integer::parseInt;
          System.out.println(parseIntReference.apply("123"));  // Output: 123

          // Reference to an Instance Method of a Particular Object
          Consumer<String> printReference = System.out::println;
          printReference.accept("Hello, Method References!");  // Output: Hello, Method References!

          // Reference to an Instance Method of an Arbitrary Object of a Particular Type
          List<String> words = Arrays.asList("apple", "orange", "banana");
          words.forEach(System.out::println);

          // Reference to a Constructor
          Supplier<List<String>> listSupplierReference = ArrayList::new;
          List<String> list = listSupplierReference.get();
          System.out.println(list);  // Output: []
      }
  }   
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default MethodReferenceExample;
