import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java11VarLambda = () => {
  const javaCode = `
  @FunctionalInterface
  interface MyFunctionalInterface {
      void myMethod(String parameter);
  }

  public class Java11LambdaExample {
      public static void main(String[] args) {
          // Java 10 or earlier
          MyFunctionalInterface java10FunctionalInterface = (String parameter) -> {
              System.out.println("Lambda expression with explicit type (Java 10): " + parameter);
          };

          java10FunctionalInterface.myMethod("Hello, Java 10!");

          // Java 11 with Local Variable Syntax for Lambda Parameters
          MyFunctionalInterface java11FunctionalInterface = (var parameter) -> {
              System.out.println("Lambda expression with inferred type (Java 11): " + parameter);
          };

          java11FunctionalInterface.myMethod("Hello, Java 11!");
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java11VarLambda;
