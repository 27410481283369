import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const CustomModulePath = () => {
  const reactCode = `
// File: index.js
const path = require('path');

// Create path aliases
const aliases = {
  components: path.resolve(__dirname, 'src/components'),
  utils: path.resolve(__dirname, 'src/utils'),
};

// Register path aliases
Object.keys(aliases).forEach((alias) => {
  require('module-alias/register')(aliases[alias]);
});

// Now, you can use path aliases in your code
const myComponent = require('components/myComponent');
const utilFunction = require('utils/utilFunction');
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="javascript"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {reactCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default CustomModulePath;
