import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const JShell = () => {
  const javaCode = `
  // Code Examples
  // Example 1: Define an integer variable
  int x = 5;

  // Example 2: Define a String variable
  String message = "Hello, JShell!";

  // Example 3: Print the message
  System.out.println(message);
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default JShell;
