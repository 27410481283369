import React from "react";
import JavaCodeRest from "./codes/communication/JavaCodeRest";
import JavaCodeGraphQL from "./codes/communication/JavaCodeGraphQL";
import JavaCodeWebSockets from "./codes/communication/JavaCodeWebSockets";
import JavaCodeEventDriven from "./codes/communication/JavaCodeEventDriven";
import JavaCodeDirectCommunication from "./codes/communication/JavaCodeDirectCommunication";

const notesCommunication = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesCommunication.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>
        How to connect front end to back end services with different
        communication methods by considering security, scalability and
        performance.
      </h4>
      <div className="post-text">
        <h4>1. RESTful APIs:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Simplicity:</b> REST (Representational State Transfer) is a
            well-defined architectural style that uses standard HTTP methods
            (GET, POST, PUT, DELETE) and status codes.
          </li>
          <li>
            <b>Statelessness:</b> Each request from the client to the server
            must contain all the information needed to understand and fulfill
            the request, making it easy to scale and maintain.
          </li>
          <li>
            <b>Caching:</b> REST APIs can take advantage of HTTP caching
            mechanisms, improving performance for read-heavy operations.
          </li>
          <li>
            <b>Wide Adoption:</b> REST is widely adopted and understood, making
            it a common choice for building APIs.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Over-fetching/Under-fetching:</b> Clients may receive more or
            less data than needed, which can impact performance.
          </li>
          <li>
            <b>Real-time Updates:</b> REST is not well-suited for real-time
            updates, as clients typically need to poll the server for changes.
          </li>
          <li>
            <b>API Versioning:</b> Managing API versioning can be challenging,
            especially when making backward-incompatible changes.
          </li>
        </ul>
        <JavaCodeRest />
        <br />
        <h4>2. WebSockets:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Real-time Communication:</b> WebSockets provide full-duplex,
            bidirectional communication, making them ideal for real-time
            applications like chat or online gaming.
          </li>
          <li>
            <b>Reduced Overhead:</b> WebSockets have less overhead compared to
            HTTP for maintaining a continuous connection.
          </li>
          <li>
            <b>Efficiency:</b> It's efficient for scenarios where small,
            frequent updates are required.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Complexity:</b> Implementing WebSockets can be more complex than
            traditional REST APIs.
          </li>
          <li>
            <b>State Management:</b> Managing the state of WebSocket connections
            on the server can be challenging.
          </li>
          <li>
            <b>Firewall and Proxy Issues:</b> WebSockets may face firewall and
            proxy restrictions, which could limit their usability in certain
            environments.
          </li>
        </ul>
        <JavaCodeWebSockets />
        <br />
        <h4>3. GraphQL:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Flexible Queries:</b> Clients can request exactly the data they
            need, reducing over-fetching and under-fetching of data.
          </li>
          <li>
            <b>Single Endpoint:</b> GraphQL provides a single endpoint for
            fetching data, simplifying client-server communication.
          </li>
          <li>
            <b>Introspection:</b> Clients can introspect the schema, making it
            easy to discover available data and operations.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Complexity</b> Implementing and optimizing a GraphQL server can
            be more complex than a REST API.
          </li>
          <li>
            <b>Performance:</b> Poorly optimized queries can lead to performance
            issues.
          </li>
          <li>
            <b>Learning Curve:</b> Developers need to learn the GraphQL query
            language and concepts.
          </li>
        </ul>
        <JavaCodeGraphQL />
        <br />
        <h4>4. Event-Driven Communication:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Decoupling:</b> Event-driven architectures allow services to be
            loosely coupled, improving scalability and maintainability.
          </li>
          <li>
            <b>Real-time Updates:</b> Events can trigger real-time updates in
            response to changes, making them suitable for notifications and
            distributed systems.
          </li>
          <li>
            <b>Scalability:</b> Event-driven systems can scale horizontally to
            handle increased load.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Complexity</b> Implementing event-driven systems can be complex,
            especially for ensuring event ordering and reliability.
          </li>
          <li>
            <b>Debugging:</b> Debugging and tracing events across services can
            be challenging.
          </li>
          <li>
            <b>Message Broker Dependency:</b> Event-driven systems typically
            rely on a message broker, introducing a potential single point of
            failure.
          </li>
        </ul>
        <JavaCodeEventDriven />
        <br />
        <h4>5. Direct Communication:</h4>
        <h4>Advantages:</h4>
        <ul>
          <li>
            <b>Simplicity:</b> Direct communication between frontend and backend
            services can be simple to implement, especially in monolithic
            architectures.
          </li>
          <li>
            <b>Efficiency:</b> It can be efficient for low-latency communication
            within the same process or on the same server.
          </li>
        </ul>
        <h4>Disadvantages:</h4>
        <ul>
          <li>
            <b>Tight Coupling</b> Direct communication can lead to tight
            coupling between frontend and backend components, making it harder
            to maintain and scale.
          </li>
          <li>
            <b>Limited Scalability:</b> In microservices architectures, direct
            communication between services can limit scalability and hinder
            fault tolerance.
          </li>
          <li>
            <b>Lack of Isolation:</b> Bugs or failures in one component can
            affect others directly.
          </li>
        </ul>
        <JavaCodeDirectCommunication />
      </div>
    </div>
  );
};
export default notesCommunication;
