import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const PrivateMethods = () => {
  const javaCode = `
    public interface Shape {
        double calculateArea();
        double calculatePerimeter();

        default double calculateDiagonal() {
            double width = getWidth();
            double height = getHeight();
            return calculateDistance(width, height);
        }

        private double calculateDistance(double x, double y) {
            return Math.sqrt(x * x + y * y);
        }

        double getWidth();
        double getHeight();
    }

    public class Rectangle implements Shape {
        private double width;
        private double height;

        public Rectangle(double width, double height) {
            this.width = width;
            this.height = height;
        }

        @Override
        public double calculateArea() {
            return width * height;
        }

        @Override
        public double calculatePerimeter() {
            return 2 * (width + height);
        }

        @Override
        public double getWidth() {
            return width;
        }

        @Override
        public double getHeight() {
            return height;
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default PrivateMethods;
