import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const StreamAPIParallel = () => {
  const javaCode = `
  List<Integer> numbers = Arrays.asList(1, 2, 3, 4, 5);

  // Sequential Stream
  int sumSequential = numbers.stream()
                             .mapToInt(Integer::intValue)
                             .sum();
  
  // Parallel Stream
  int sumParallel = numbers.parallelStream()
                           .mapToInt(Integer::intValue)
                           .sum();  
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default StreamAPIParallel;
