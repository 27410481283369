import React from "react";
import "react-circular-progressbar/dist/styles.css";

import AOS from "aos";
AOS.init();

const hero = () => {
  return (
    <div className="v-center">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <h2
              data-aos="fade-up"
              data-aos-delay="0"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            >
              About Me
            </h2>
            <div
              className="space-border"
              data-aos="fade-up"
              data-aos-delay="20"
              data-aos-duration="1000"
              data-aos-easing="ease"
              data-aos-once="true"
            ></div>
          </div>
          <div
            className="col-md-8 text-center m-auto"
            data-aos="fade-up"
            data-aos-delay="60"
            data-aos-duration="1000"
            data-aos-easing="ease"
            data-aos-once="true"
          >
            <p>
              I am a passionate and results-driven Full Stack Software Engineer
              from Istanbul with expertise spanning web and desktop
              applications, my journey has advanced to roles as a Project
              Software Technical Manager and Web Technologies and Visualization
              Team Leader, I love leveraging cutting-edge technologies to
              deliver innovative solutions, get new experiences and always learn
              from my surroundings.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
