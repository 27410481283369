import React from "react";
import FunctionalInterface from "./codes/lambda/FunctionalInterface";
import Sorting from "./codes/lambda/Sorting";
import EventHandling from "./codes/lambda/EventHandling";
import RunnableAndCallable from "./codes/lambda/RunnableAndCallable";
import StreamAPI from "./codes/lambda/StreamAPI";

const notesLambdaExpression = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesLambda.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Lambda Expressions</h4>
        <p>
          Java 8 Lambda Expressions are a powerful feature that allows you to
          write more concise and expressive code. They are closely tied to
          functional programming concepts and are widely used in modern Java
          development. Let's dive into a detailed explanation of Lambda
          Expressions and their various usages:
        </p>
        <ul>
          <li>
            <b>What are Lambda Expressions?</b> Lambda Expressions, introduced
            in Java 8, are a way to define and use anonymous functions or
            closures. These functions are often used to encapsulate a block of
            code and can be treated as objects. Lambda expressions provide a
            shorter, more readable syntax for implementing single-method
            interfaces (functional interfaces).
          </li>
          <li>
            <b>Syntax of Lambda Expressions:</b> The basic syntax of a lambda
            expression consists of three parts:{" "}
            <code>(parameters) -&#62; expression or statement block</code>
            <ul>
              <li>
                <b>parameters:</b> These represent the input to the lambda
                expression and can be empty or contain one or more parameters.
                -&#62;: The arrow operator separates the parameter list from the
                body of the lambda expression. expression or statement block:
                This is the code that gets executed when the lambda expression
                is invoked. It can be a single expression or a block of
                statements enclosed in curly braces {}.
              </li>
              <li>
                <b>-&#62;:</b> The arrow operator separates the parameter list
                from the body of the lambda expression.
              </li>
              <li>
                <b>expression or statement block:</b> This is the code that gets
                executed when the lambda expression is invoked. It can be a
                single expression or a block of statements enclosed in curly
                braces {}.
              </li>
            </ul>
          </li>
          <li>
            <b>Functional Interfaces:</b> Lambda expressions are commonly used
            with functional interfaces, which are interfaces that have exactly
            one abstract method. Java 8 introduced the @FunctionalInterface
            annotation to indicate that an interface is meant to be used as a
            functional interface. Functional interfaces serve as the target
            types for lambda expressions.
          </li>
          <FunctionalInterface />
          <li>
            <b>Usages of Lambda Expressions:</b>Lambda expressions have various
            use cases in Java development:
            <ul>
              <li>
                <b>Stream API:</b>Lambda expressions are extensively used with
                the Stream API to perform operations on collections (lists,
                sets, maps) in a functional and declarative way. This includes
                filtering, mapping, reducing, and more.
              </li>
              <StreamAPI />
              <li>
                <b>Runnable and Callable:</b> Lambda expressions simplify the
                creation of Runnable and Callable instances when working with
                threads and concurrency.
              </li>
              <RunnableAndCallable />
              <li>
                <b>Event Handling:</b> Lambda expressions are commonly used in
                event-driven programming, especially when defining event
                listeners for user interfaces.
              </li>
              <EventHandling />
              <li>
                <b>Sorting:</b>Lambda expressions can be used with sorting
                algorithms to provide custom sorting criteria.
              </li>
              <Sorting />
              <li>
                <b>Functional Programming:</b> Lambda expressions are
                fundamental to functional programming paradigms, allowing
                functions to be treated as first-class citizens and enabling
                functional programming techniques in Java.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default notesLambdaExpression;
