import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java9 = () => {
  const javaCode = `
  // Module System (Project Jigsaw)
  module com.example.module {
      requires some.other.module;
      exports com.example.package;
  }

  // JShell (Interactive Shell)
  // Example in JShell
  int x = 5;
  x ==> 5

  // HTTP/2 Client
  import java.net.http.HttpClient;
  import java.net.http.HttpRequest;
  import java.net.http.HttpResponse;

  HttpClient client = HttpClient.newHttpClient();
  HttpRequest request = HttpRequest.newBuilder().uri(new URI("https://example.com")).build();
  HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());

  // Improved Process API
  ProcessBuilder processBuilder = new ProcessBuilder("echo", "Hello, Process API!");
  Process process = processBuilder.start();
  boolean completed = process.waitFor(5, TimeUnit.SECONDS);
  System.out.println("Exit Code: " + process.exitValue());
  if (!completed) {
      process.destroyForcibly();
  }

  // Private Methods in Interfaces
  public interface MyInterface {
      default void publicMethod() {
          // Public method implementation
      }

      private void privateMethod() {
          // Private method implementation
      }
  }

  // Improved Stream API
  List<String> myList = Arrays.asList("a", "b", "c", "d", "e");
  // New stream methods
  myList.stream().takeWhile(s -> !s.equals("c")).forEach(System.out::println);
  myList.stream().dropWhile(s -> !s.equals("c")).forEach(System.out::println);

  // Try-With-Resources Enhancements
  BufferedReader reader = new BufferedReader(new FileReader("example.txt"));
  try (reader) {
      // Use the reader
  } catch (IOException e) {
      // Handle exception
  }

  // Diamond Operator for Anonymous Classes
  // Before Java 9
  List<String> myList = new ArrayList<>();
  // With Diamond Operator for Anonymous Classes (Java 9 and later)
  List<String> myList = new ArrayList<>() { /* Anonymous class implementation */ };

  // Multi-Release JARs
  /src
      /main
          /java
              /com
                  /example
                      /MyClass.java
                      /MyClass_v9.java

  // Reactive Streams API
  // Example using Reactive Streams API
  Flow.Publisher<String> publisher = ...;
  Flow.Subscriber<String> subscriber = ...;
  publisher.subscribe(subscriber);

  // Multi-Resolution Images
  // Example using MultiResolutionImage
  Image image = new Image("path/to/image.png");
  MultiResolutionImage multiResImage = new MultiResolutionImage(image);

  // Java Platform Logging API and Service
  import java.util.logging.Logger;

  public class LoggingExample {
      private static final Logger logger = Logger.getLogger(LoggingExample.class.getName());

      public void performLogging() {
          logger.info("This is an information message.");
      }
  }

  // Compact Strings
  String compactString = "Hello, Compact Strings!";

  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java9;
