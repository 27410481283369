import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const DescriptionExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Description;
    /** In this example, we define a @Description annotation on 
     * a Spring bean method within a Spring configuration class. 
     * The @Description annotation is used to provide a description 
     * for the bean. The code example shows how to use this 
     * annotation in your Spring configuration.*/
    @Configuration
    public class MyConfiguration {
    
        @Bean
        @Description("This is a sample bean description.")
        public MyBean myBean() {
            return new MyBean();
        }
    }
    
    public class MyBean {
        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default DescriptionExample;
