import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const MethodReferenceInstance = () => {
  const javaCode = `
  // Lambda Expression
  Consumer<String> printLambda = s -> System.out.println(s);

  // Method Reference
  Consumer<String> printReference = System.out::println;
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default MethodReferenceInstance;
