import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8DefaultMethods = () => {
  const javaCode = `
// Default Methods: Default methods allow you to add new methods to existing interfaces without breaking the classes that implement those interfaces. This is useful for backward compatibility.
// Default method in an interface
interface MyInterface {
    void myMethod();

    default void myDefaultMethod() {
        System.out.println("Default implementation");
    }
}
`;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8DefaultMethods;
