import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const PropertySourcesExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.PropertySources;
    import org.springframework.context.annotation.PropertySource;

/** In this example, we use the @PropertySources annotation 
 * to specify multiple properties files (app.properties and 
 * database.properties) located on the classpath. 
 * The properties defined in these files will be loaded 
 * into the Spring application context. */

    @Configuration
    @PropertySources({
        @PropertySource("classpath:app.properties"),
        @PropertySource("classpath:database.properties")
    })
    public class MyConfig {
    
        // Configuration logic here
        
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default PropertySourcesExample;
