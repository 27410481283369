import React from "react";
import HTTPClient from "./codes/HTTPClient/HTTPClient";

const notesHTTPClient = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesClient.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 9 key features</h4>
      <div className="post-text">
        <ul>
          <li>
            <strong>Java 9 HTTP/2 Client:</strong>
            <p>
              The Java 9 HTTP/2 Client is a modern, lightweight, and
              asynchronous API for interacting with HTTP/2 servers. It is
              designed to replace the older `HttpURLConnection` API and provide
              more features for handling HTTP requests and responses.
            </p>
          </li>
          <li>
            <strong>Benefits of HTTP/2 Client:</strong>
            <ol>
              <li>
                <strong>HTTP/2 Support:</strong> The client supports the HTTP/2
                protocol, providing improved performance and efficiency compared
                to HTTP/1.1.
              </li>
              <li>
                <strong>Asynchronous API:</strong> The API is designed to work
                asynchronously, allowing for non-blocking interactions with
                servers, which is essential for building responsive and scalable
                applications.
              </li>
              <li>
                <strong>Stream Handling:</strong> HTTP/2 supports multiplexing,
                enabling multiple streams (requests and responses) to be sent
                concurrently over a single connection, leading to better
                resource utilization.
              </li>
              <li>
                <strong>Improved Security:</strong> The client is designed to
                handle secure connections seamlessly, supporting HTTPS by
                default.
              </li>
              <li>
                <strong>Flexible and Extensible:</strong> The API provides a
                flexible and extensible framework for working with HTTP, making
                it easy to customize requests and responses.
              </li>
            </ol>
          </li>
          <li>
            <p>Example:</p>
            <HTTPClient />
          </li>
          <li>
            <strong>What's New:</strong>
            <p>
              The HTTP/2 Client brings a modern and feature-rich API for
              handling HTTP interactions, especially with support for the HTTP/2
              protocol, asynchronous operations, and improved security features.
            </p>
          </li>
          <li>
            <strong>Unique Benefits:</strong>
            <p>
              Java 9's HTTP/2 Client introduces an asynchronous and flexible
              approach to working with HTTP, leveraging the advancements in the
              HTTP/2 protocol for enhanced performance and resource utilization.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default notesHTTPClient;
