import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const MethodReferenceStatic = () => {
  const javaCode = `
  // Lambda Expression
  Function<String, Integer> parseIntLambda = s -> Integer.parseInt(s);

  // Method Reference
  Function<String, Integer> parseIntReference = Integer::parseInt;
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default MethodReferenceStatic;
