import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ReactiveStreams = () => {
  const javaCode = `
  import java.util.concurrent.Flow;
  import java.util.concurrent.SubmissionPublisher;

  // A simple example demonstrating the Reactive Streams API in Java 9
  public class ReactiveStreamsExample {

      public static void main(String[] args) throws InterruptedException {
          // Create a SubmissionPublisher (a Publisher implementation)
          SubmissionPublisher<String> publisher = new SubmissionPublisher<>();

          // Create a simple subscriber
          Flow.Subscriber<String> subscriber = new SimpleSubscriber();

          // Register the subscriber with the publisher
          publisher.subscribe(subscriber);

          // Publish data asynchronously to the subscribers
          publisher.submit("Data 1");
          publisher.submit("Data 2");
          publisher.submit("Data 3");

          // Close the publisher (indicating no more data will be published)
          publisher.close();

          // Allow time for subscribers to process the data
          Thread.sleep(1000);
      }

      // A simple Subscriber implementation
      static class SimpleSubscriber implements Flow.Subscriber<String> {

          private Flow.Subscription subscription;

          @Override
          public void onSubscribe(Flow.Subscription subscription) {
              this.subscription = subscription;
              subscription.request(1); // Request the first item when subscription starts
          }

          @Override
          public void onNext(String item) {
              System.out.println("Received: " + item);
              subscription.request(1); // Request the next item after processing the current one
          }

          @Override
          public void onError(Throwable throwable) {
              System.err.println("Error occurred: " + throwable.getMessage());
          }

          @Override
          public void onComplete() {
              System.out.println("Processing complete.");
          }
      }
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ReactiveStreams;
