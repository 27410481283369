import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const JavaCodeEventDriven = () => {
  const javaCode = `
  import org.apache.kafka.clients.consumer.Consumer;
  import org.apache.kafka.clients.consumer.ConsumerConfig;
  import org.apache.kafka.clients.consumer.ConsumerRecord;
  import org.apache.kafka.clients.consumer.KafkaConsumer;
  import org.apache.kafka.common.serialization.StringDeserializer;
  
  import java.time.Duration;
  import java.util.Collections;
  import java.util.Properties;
  
  public class KafkaConsumerExample {
      public static void main(String[] args) {
          Properties properties = new Properties();
          properties.put(ConsumerConfig.BOOTSTRAP_SERVERS_CONFIG, "localhost:9092");
          properties.put(ConsumerConfig.GROUP_ID_CONFIG, "my_group");
          properties.put(ConsumerConfig.KEY_DESERIALIZER_CLASS_CONFIG, StringDeserializer.class.getName());
          properties.put(ConsumerConfig.VALUE_DESERIALIZER_CLASS_CONFIG, StringDeserializer.class.getName());
  
          Consumer<String, String> consumer = new KafkaConsumer<>(properties);
  
          consumer.subscribe(Collections.singletonList("my_topic"));
  
          while (true) {
              ConsumerRecords<String, String> records = consumer.poll(Duration.ofMillis(100));
  
              for (ConsumerRecord<String, String> record : records) {
                  System.out.printf("Received message: %s%n", record.value());
              }
          }
      }
  }  
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default JavaCodeEventDriven;
