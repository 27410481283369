import React from "react";

const footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <a href="https://ouuu.io" target="_blank" rel="noreferrer">
              <span className="copy">&copy; Copyright 2023 - Ufuk Tosun</span>
            </a>
          </div>
          <div className="col-md-6">
            <div className="social-icons">
              <a
                href="https://www.interaction-design.org/members/ufuk-tosun"
                target="_blank"
                rel="noreferrer"
              >
                {/* <i className="fa fa-pagelines"></i> */}
                <b>IDxF</b>
              </a>
              <a
                href="https://www.linkedin.com/in/tosunufuk/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-linkedin"></i>
              </a>
              <a
                href="https://github.com/tosunufuk"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fa fa-github"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default footer;
