import React from "react";
import Java11VarLambda from "./codes/java11varLambda/Java10Var";

const NotesLocalVariableSyntax = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesLVSLP.jpg"
        className="img-fullwidth rounded"
      />
      <div className="post-info">
        <span className="post-date">January 1, 2019</span>
        <span className="post-like">200</span>
        <span className="post-comment">8</span>
      </div>

      <h4>Java 11 Local Variable Syntax for Lambda Parameters (JEP 323)</h4>

      <div className="post-text">
        <ol>
          <li>
            <strong>Overview:</strong>
            <ul>
              <li>
                <strong>
                  JEP 323 - Local Variable Syntax for Lambda Parameters:
                </strong>{" "}
                Enables the use of the `var` keyword for the formal parameters
                of implicitly typed lambda expressions.
              </li>
            </ul>
          </li>

          <li>
            <strong>Benefit and Innovation:</strong>
            <ul>
              <li>
                <strong>Benefit:</strong> Allows the `var` keyword to be used
                for lambda parameters, reducing the need to explicitly declare
                their types.
              </li>
              <li>
                <strong>Innovation:</strong> Introduces a more concise and
                expressive syntax for lambda expressions.
              </li>
            </ul>
          </li>

          <li>
            <strong>What's New:</strong>
            <ul>
              <li>
                <strong>Introduction of `var` in Lambda Parameters:</strong>
                <ul>
                  <li>
                    Before Java 11, lambda parameters required explicit type
                    declarations.
                  </li>
                  <li>
                    With JEP 323, developers can use the `var` keyword, relying
                    on type inference.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <strong>Benefits and Advantages:</strong>
            <ul>
              <li>
                <strong>Conciseness:</strong>
                <ul>
                  <li>Results in cleaner and more concise code.</li>
                  <li>
                    Developers can express the same functionality with fewer
                    characters, improving code readability.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Enhanced Readability:</strong>
                <ul>
                  <li>
                    Enhances readability without sacrificing strong typing.
                  </li>
                  <li>
                    Developers can focus on the logic of the lambda expression
                    without the verbosity of explicit type declarations.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <strong>Upsides:</strong>
            <ul>
              <li>
                <strong>Cleaner Syntax:</strong>
                <ul>
                  <li>
                    The use of `var` reduces boilerplate code, making lambda
                    expressions more streamlined.
                  </li>
                  <li>
                    Especially beneficial when lambda expressions have complex
                    or nested types.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Reduced Verbosity:</strong>
                <ul>
                  <li>
                    By omitting explicit type declarations, lambda expressions
                    become shorter and easier to read.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <strong>Challenges:</strong>
            <ul>
              <li>
                <strong>Potential Readability Concerns:</strong>
                <ul>
                  <li>
                    If overused, the reliance on `var` might make code less
                    readable, especially in cases where type inference is not
                    immediately obvious.
                  </li>
                  <li>
                    Developers need to strike a balance between concise code and
                    clear readability.
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <strong>Considerations:</strong>
            <ul>
              <li>
                <strong>Judicious Use:</strong>
                <ul>
                  <li>
                    Developers need to use `var` judiciously, balancing the
                    benefits of concise code with the need for clear and
                    understandable code.
                  </li>
                  <li>
                    It's recommended to use `var` when the type is obvious or
                    when it improves code readability.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
        <Java11VarLambda />
        <p>
          The first lambda expression uses explicit type declaration, as in Java
          10 or earlier, and the second lambda expression utilizes Local
          Variable Syntax for Lambda Parameters introduced in Java 11 with the
          use of var.
        </p>
      </div>
    </div>
  );
};

export default NotesLocalVariableSyntax;
