import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const Java8Nashorn = () => {
  const javaCode = `
  // Nashorn JavaScript Engine: Java 8 includes a new JavaScript engine called Nashorn, which allows you to execute JavaScript code from within Java applications.
  // Executing JavaScript code using Nashorn
  ScriptEngineManager engineManager = new ScriptEngineManager();
  ScriptEngine engine = engineManager.getEngineByName("nashorn");
  try {
      engine.eval("print('Hello from JavaScript!');");
  } catch (ScriptException e) {
      e.printStackTrace();
  }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default Java8Nashorn;
