import React from "react";
import CompletableFuture from "./codes/completablefuture/CompletableFuture";

const notesNashornJS = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesCompletableFuture.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">April 1, 2018</span>
        <span className="post-like">181</span>
        <span className="post-comment">5</span>
      </div>

      <h4>Java 8 key features</h4>
      <div className="post-text">
        <h4>Completable Future</h4>
        <p>
          The CompletableFuture class in Java 8 is part of the
          java.util.concurrent package and provides a powerful way to work with
          asynchronous programming and compose asynchronous operations.
        </p>
        <ul>
          <li>
            <h5>Asynchronous and Concurrent Programming:</h5>
            <b>Benefit:</b> CompletableFuture enables asynchronous programming,
            allowing you to express and compose asynchronous computations.
            <br />
            <b>Advantage:</b> Developers can write concurrent and non-blocking
            code to improve the efficiency of applications.
          </li>

          <li>
            <h5>Composition of Asynchronous Operations:</h5>
            <b>Benefit:</b> CompletableFuture allows the composition of multiple
            asynchronous operations.
            <br />
            <b>Advantage:</b> Developers can chain together a sequence of
            asynchronous operations in a more readable and maintainable way.
          </li>

          <li>
            <h5>Exception Handling:</h5>
            <b>Benefit:</b> CompletableFuture provides robust support for
            exception handling.
            <br />
            <b>Advantage:</b> Developers can easily handle exceptions that may
            occur during the execution of asynchronous operations.
          </li>

          <li>
            <h5>Combining Multiple CompletableFutures:</h5>
            <b>Benefit:</b> CompletableFuture offers methods for combining the
            results of multiple CompletableFutures.
            <br />
            <b>Advantage:</b> This facilitates parallel execution of independent
            tasks and combining their results.
          </li>

          <li>
            <h5>Timeouts and Error Handling:</h5>
            <b>Benefit:</b> CompletableFuture supports setting timeouts for
            operations.
            <br />
            <b>Advantage:</b> Developers can control the maximum time allowed
            for the completion of an asynchronous operation.
          </li>

          <li>
            <h5>Cancellation and Interruption:</h5>
            <b>Benefit:</b> CompletableFuture allows cancellation of
            asynchronous tasks.
            <br />
            <b>Advantage:</b> Developers can manage resources efficiently by
            canceling tasks that are no longer needed.
          </li>
        </ul>
        <p>Examples:</p>
        <CompletableFuture />
      </div>
    </div>
  );
};
export default notesNashornJS;
