import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const ImportExample = () => {
  const javaCode = `
    import org.springframework.context.annotation.Bean;
    import org.springframework.context.annotation.Configuration;
    import org.springframework.context.annotation.Import;
    
/** In this example, we use the @Import annotation to import 
 * another Spring configuration class (OtherConfig) into the 
 * current configuration class (MyConfig). This allows you to 
 * combine multiple configuration classes and define beans in 
 * a modular way. */

    @Configuration
    @Import(OtherConfig.class)
    public class MyConfig {
    
        @Bean
        public MyBean myBean() {
            return new MyBean();
        }
    }
    
    @Configuration
    public class OtherConfig {
        // Other configuration logic here
    }
    
    public class MyBean {
        // Bean logic here
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default ImportExample;
