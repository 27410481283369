import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const SpringBootApplicationExample = () => {
  const javaCode = `
    import org.springframework.boot.SpringApplication;
    import org.springframework.boot.autoconfigure.SpringBootApplication;

/** In this example, we use the @SpringBootApplication annotation to 
 * declare the main application class. This annotation is typically 
 * used to enable Spring Boot auto-configuration and component scanning 
 * in a Spring Boot application. */

    @SpringBootApplication
    public class MyApplication {
      
      public static void main(String[] args) {
        SpringApplication.run(MyApplication.class, args);
      }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default SpringBootApplicationExample;
