import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const FunctionalInterface = () => {
  const javaCode = `
@FunctionalInterface
interface Calculator {
    int operate(int a, int b);
}

public class LambdaExample {
  public static void main(String[] args) {
      // Using a lambda expression to create a Calculator instance
      Calculator addition = (a, b) -> a + b;
      int result = addition.operate(5, 3); // Result: 8
  }
}
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers="true"
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default FunctionalInterface;
