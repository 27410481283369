import React from "react";
import Java10Var from "./codes/java10var/Java10Var";

const notesJava10Var = () => {
  return (
    <div>
      <img
        alt="blogimg"
        src="./img/notes/notesJava10Var.jpg"
        className="img-fullwidth rounded"
      />

      <div className="post-info">
        <span className="post-date">December 26, 2023</span>
        <span className="post-like">42</span>
        <span className="post-comment">8</span>
      </div>

      <h4>Java 10 Local-Variable Type Inference (var)</h4>
      <div className="post-text">
        <ul>
          <li>
            <strong>Introduction:</strong>
            <p>
              Java 10 introduced local-variable type inference, allowing
              developers to use the <code>var</code> keyword for declaring
              variables. This feature focuses on improving code readability and
              reducing boilerplate code.
            </p>
          </li>
          <li>
            <strong>Benefits of Java 10 Local-Variable Type Inference:</strong>
            <ul>
              <li>
                <strong>Conciseness:</strong> The <code>var</code> keyword
                reduces verbosity, making code more concise by inferring the
                type of a variable from its initializer.
              </li>
              <li>
                <strong>Readability:</strong> By eliminating explicit type
                declarations, the code becomes cleaner and more readable,
                especially in cases where the type is evident from the
                assignment.
              </li>
              <li>
                <strong>Compatibility:</strong> It maintains compatibility with
                existing code and does not introduce changes to the runtime
                behavior of the application.
              </li>
            </ul>
          </li>
          <li>
            <strong>Upsides:</strong>
            <ul>
              <li>
                <strong>Reduced Boilerplate:</strong> Developers can write
                concise code without sacrificing type safety, reducing
                boilerplate associated with explicit type declarations.
              </li>
              <li>
                <strong>Enhanced Flexibility:</strong> The <code>var</code>
                keyword allows for more flexible and expressive coding patterns,
                particularly in cases where the type is obvious.
              </li>
            </ul>
          </li>
          <li>
            <strong>Downsides and Challenges:</strong>
            <ul>
              <li>
                <strong>Overuse:</strong> Overuse of the <code>var</code>
                keyword may lead to less readable code, especially in scenarios
                where the type is not immediately evident.
              </li>
              <li>
                <strong>Debugging:</strong> Debugging might become slightly
                challenging in cases where the inferred type is not explicitly
                visible in the code.
              </li>
            </ul>
          </li>
          <li>
            <strong>Examples:</strong>
            <Java10Var />
            <strong>In this example:</strong>
            <ul>
              <li>
                <strong>var greeting</strong> infers the type as{" "}
                <strong>String</strong>.
              </li>
              <li>
                <strong>var numbers</strong> infers the type as{" "}
                <strong>{"ArrayList < Integer >"}</strong>.
              </li>
              <li>
                <strong>var personInfo</strong> infers the type as{" "}
                <strong>{"HashMap<String, String>"}</strong>.
              </li>
              <li>
                The enhanced for loop uses <strong>var</strong> to iterate over
                the entries of the map.
              </li>
              <li>
                <strong>var square</strong> is used in a lambda expression.
              </li>
            </ul>
          </li>
          <li>
            <strong>Conclusion:</strong>
            <p>
              Java 10's introduction of local-variable type inference with the
              <code>var</code> keyword brings enhanced code conciseness and
              readability. While offering benefits in terms of reduced
              boilerplate, developers should use the feature judiciously to
              maintain code clarity and understandability.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default notesJava10Var;
