import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

const CommonsLogAnnotationExample = () => {
  const javaCode = `
    import org.apache.commons.logging.Log;
    import org.apache.commons.logging.LogFactory;
    import org.springframework.stereotype.Service;
    import lombok.extern.apachecommons.CommonsLog;

/** In this example, we use @CommonsLog to inject 
 * a Commons Logging logger into the MyService class. 
 * The logger can then be used to log messages within 
 * the class. */

    @Service
    @CommonsLog
    public class MyService {
    
        public void performAction() {
            Log log = LogFactory.getLog(MyService.class);
            log.info("Performing an action in MyService");
        }
    }
  `;

  return (
    <div>
      <SyntaxHighlighter
        language="java"
        style={vscDarkPlus}
        showLineNumbers={true}
      >
        {javaCode}
      </SyntaxHighlighter>
    </div>
  );
};

export default CommonsLogAnnotationExample;
